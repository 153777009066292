import { Card, Tooltip, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, useEffect, useRef, useState } from "react";
import { usePatientHealthParameterVitalSignReadingCardStyle } from "./usePatientHealthParameterVitalSignReadingCardStyle";

type PatientHealthParameterVitalSignReadingCardProps = {
  title: string;
  value?: number | string;
  unit?: string;
  source?: string;
};

export const PatientHealthParameterVitalSignReadingCard: FC<PatientHealthParameterVitalSignReadingCardProps> = props => {
  const { title, value, unit, source } = props;

  const { t } = useTranslation("provider");

  const { classes } = usePatientHealthParameterVitalSignReadingCardStyle();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width - 30);
    }
  }, []);

  return (
    <Card ref={ref} className={classes.root}>
      <Typography className={classes.value}>
        {value || t("N/A")}

        {value && unit && (
          <Typography component={"span"} className={classes.unit}>
            <sup>{unit}</sup>
          </Typography>
        )}
      </Typography>
      <Tooltip title={title}>
        <Typography className={classes.title} width={width}>
          {title}
        </Typography>
      </Tooltip>
      <Typography className={classes.source}>{source ? `${t("By")} ${source}` : ""}</Typography>
    </Card>
  );
};
