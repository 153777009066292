/* eslint-disable max-lines */
import { makeVar, useReactiveVar } from "@apollo/client";
import { Visit, VisitStatus } from "@health/queries/types";
import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Container, CustomIcon, Grid, useAddToast, useTheme } from "@toolkit/ui";
import moment from "moment";
import { ReJoinableVisitStatuses } from "pages/programs-calls/components/ActiveCall/utils";
import { useVisitCallRejoinMutation } from "pages/programs-calls/gql";
import { useEffect, useState } from "react";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { AppointmentsView } from "./AppointmentsView";
import { DeclineModel } from "./DeclineModel";
import { IncomingCalls } from "./IncomingCalls";
import { VisitsView } from "./VisitsView";
import { useMeVisitsCallInProgressQuery, useVisitCallEndMutation } from "./gql";
import useVisitStartingNowNotification from "./useVisitStartingNowNotification";
import { openMeetingPlatformLink } from "./utils";
import { useActiveCall } from "@health/meetora";
import { VisitCallStatusBar } from "../VisitCallStatusBar";

export const hasCallVar = makeVar(false);

export const VisitStartingNowNotification = () => {
  const isInCall = !!useActiveCall();
  const { startNotificationSound, stopNotificationSound } = useVisitStartingNowNotification();
  const { t } = useTranslation("provider");
  const [state, setState] = useState<{
    id: string;
    status;
    meetingPlatformLink: string | null | undefined;
    number: string | null | undefined;
    appointment: string | null | undefined;
  }>();
  const [lastActiveVisit, setLastActiveVisit] = useState<Visit>();
  const hasCallValue = useReactiveVar(hasCallVar);
  const { accessToken } = useOidcUserProfile();
  const { succeeded, failed } = useAddToast();

  const [visitCallEnd] = useVisitCallEndMutation({
    onCompleted: request => {
      const visitErrors = request?.visitCallEnd?.visitErrors;
      if (visitErrors?.length === 0) {
        succeeded(t("Visit call ended successfully"));
        hasCallVar(false);
      } else {
        formatMessageErrors(visitErrors);
        failed(t("Visit Call End Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const { refetch } = useMeVisitsCallInProgressQuery({
    skip: !accessToken,
    nextFetchPolicy: "cache-and-network",
    onCompleted: response => {
      const activeVisit = response.me?.visits?.edges.map(visit => visit.node)?.[0];
      setLastActiveVisit(activeVisit as Visit);
      if (activeVisit) {
        const { id, status, meetingPlatformLinkForJoinCall, number, appointment } = activeVisit;
        setState({
          id,
          status: status!,
          meetingPlatformLink: meetingPlatformLinkForJoinCall,
          number,
          appointment: appointment?.id,
        });
        hasCallVar(true);
      } else {
        hasCallVar(false);
      }
    },
  });

  const [rejoinCallMutation] = useVisitCallRejoinMutation();

  const handleJoin = visitResponse => {
    stopNotificationSound();
    openMeetingPlatformLink(visitResponse?.meetingPlatformLinkForJoinCall!, visitResponse?.id);
  };

  const handleJoinNow = () => {
    if (state?.status! === VisitStatus.CallInProgress && state?.meetingPlatformLink) {
      openMeetingPlatformLink(state?.meetingPlatformLink!, state?.id);
      stopNotificationSound();
    } else if (ReJoinableVisitStatuses.includes(state?.status!)) {
      rejoinCallMutation({
        variables: { visitId: state?.id! },
        onCompleted: request => {
          const visitResponse = request?.visitCallRejoin;
          if (visitResponse?.visitErrors?.length === 0) {
            failed(formatMessageErrors(visitResponse?.visitErrors));
          } else {
            handleJoin(visitResponse?.visit);
          }
        },
        onError: ({ graphQLErrors }) => {
          failed(formatGraphQLError(graphQLErrors));
        },
      });
    } else if (state?.meetingPlatformLink) {
      handleJoin(state);
    } else {
      refetch();
    }
  };

  const handleDecline = () => {
    visitCallEnd({
      variables: {
        visitId: state?.id!,
      },
    });
  };

  const handleClose = () => {
    hasCallVar(false);
  };

  useOnHealthProgramStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      refetch();
    }
  });

  useEffect(() => {
    const doctorJoinedDate = moment(lastActiveVisit?.doctorJoinedDate);
    const doctorLeftDate = moment(lastActiveVisit?.doctorLeftDate);

    if (
      !isInCall &&
      hasCallValue &&
      (!lastActiveVisit?.doctorJoined || (lastActiveVisit?.doctorLeft && doctorLeftDate.isAfter(doctorJoinedDate)))
    ) {
      startNotificationSound();
    } else {
      stopNotificationSound();
    }
  }, [hasCallValue, isInCall]);

  const theme = useTheme();

  if (isInCall) {
    return <VisitCallStatusBar />;
  }

  return (
    <Box display={!hasCallValue ? "none" : "flex"} bgcolor={theme.palette.common.white} width='100vw' boxShadow={theme.mixins.shadows.md}>
      <Container fixed>
        <Grid container spacing={2} justifyContent='center' alignItems='center' height='100px'>
          {state?.status === VisitStatus.Assigned ? (
            <IncomingCalls id={state?.id!} onClose={handleClose} />
          ) : (
            <>
              {state?.appointment ? <AppointmentsView /> : <VisitsView number={state?.number!} />}

              <Grid item xs='auto'>
                <Box height='100%' display='flex' alignItems='center'>
                  <Button
                    disabled={
                      !state?.meetingPlatformLink ||
                      (state?.status !== VisitStatus.CallInProgress && !ReJoinableVisitStatuses.includes(state?.status!))
                    }
                    onClick={handleJoinNow}
                  >
                    {t("Join Now")}
                  </Button>
                  <DeclineModel onDecline={handleDecline} />
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs='auto'>
            <Button variant='text' onClick={handleClose} startIcon={<CustomIcon icon='close' />}>
              {t("Close")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
