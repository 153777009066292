import { Box, Button, CustomIcon, IconButton, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { Trans, useTranslation } from "@toolkit/i18n";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { FC } from "react";

type AccessDeniedViewProps = {
  onRequestAccess: () => void;
  isLoading: boolean;
};

export const AccessDeniedView: FC<AccessDeniedViewProps> = ({ isLoading, onRequestAccess: handleRequestAccess }) => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const { patientName, onClose: handleClose } = useProfileContext();
  const PatientName = (
    <TruncateTypography
      sx={{ display: "inline-block" }}
      text={patientName || t("Patient")}
      fontWeight={theme.mixins.fonts.fontWeight.medium}
    />
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <Box
        p={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Access Denied")}
        </Typography>
        <Box>
          <IconButton onClick={handleClose}>
            <CustomIcon icon='xAlert' />
          </IconButton>
        </Box>
      </Box>
      <Typography fontSize={theme.mixins.fonts.fontSize.md} m={3}>
        <Trans t={t} i18nKey={"request-medical-profile"} components={{ 1: PatientName }} />
      </Typography>
      <Box
        p={1}
        sx={{
          boxShadow: theme.mixins.shadows.xs,
        }}
      >
        <Button disabled={isLoading} onClick={handleRequestAccess}>
          {isLoading ? t("Loading...") : t("Send")}
        </Button>
      </Box>
    </Box>
  );
};
