import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { VisitNote } from "./Notes";
import { ToPatientNote } from "./components/ToPatientNote/ToPatientNote";

export const VisitNotePage: FC<{ visitId?: string; onVisitNoteChanged?: (note: string) => void }> = ({ visitId, onVisitNoteChanged }) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const visitIdParams = params.get("visitId");

  return (
    <>
      <VisitNote visitId={visitIdParams! || visitId!} token={token!} onVisitNoteChanged={onVisitNoteChanged} />
      <ToPatientNote visitId={visitIdParams! || visitId!} token={token!} />
    </>
  );
};

VisitNotePage.defaultProps = {
  visitId: undefined,
  onVisitNoteChanged: undefined,
};
