import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { useSiteSettingsGetQuery } from "../../../../shared/gql/queries";
import { toTitleCase } from "@toolkit/core";
export const useRemittanceActivitiesListColumns = () => {
    var _a;
    const { t } = useTranslation("domains");
    const { data } = useSiteSettingsGetQuery({
        fetchPolicy: "cache-first",
    });
    const defaultCurrency = ((_a = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _a === void 0 ? void 0 : _a.defaultCurrency) || "";
    const theme = useTheme();
    return useMemo(() => {
        return [
            {
                key: "code",
                header: t("Code"),
                accessor: ({ code, codeLookup }) => _jsx(TruncateTypography, { text: toTitleCase((codeLookup === null || codeLookup === void 0 ? void 0 : codeLookup.name) || code || "-") }),
            },
            {
                key: "type",
                header: t("Type"),
                accessor: ({ type, typeLookup }) => _jsx(TruncateTypography, { text: (typeLookup === null || typeLookup === void 0 ? void 0 : typeLookup.name) || (type !== null && type !== void 0 ? type : "").toString() || "-" }),
            },
            {
                key: "quantity",
                header: t("Quantity"),
                accessor: "quantity",
            },
            {
                key: "netAmount",
                header: t("Net Amount"),
                accessor: ({ net }) => (net ? net + " " + defaultCurrency : "-"),
            },
            {
                key: "paymentAmount",
                header: t("Payment Amount"),
                accessor: ({ paymentAmount }) => (paymentAmount ? paymentAmount + " " + defaultCurrency : "-"),
            },
            {
                key: "patientShare",
                header: t("patientShare"),
                accessor: ({ patientShare }) => (patientShare ? patientShare + " " + defaultCurrency : "-"),
            },
            {
                key: "activityStatus",
                header: t("status"),
                accessor: ({ denialCode }) => denialCode ? (_jsx(Typography, { color: theme.palette.error.main, children: t("Rejected") })) : (_jsx(Typography, { color: theme.palette.success.main, children: t("Approved") })),
            },
            {
                key: "denialCode",
                header: t("Denial Code"),
                accessor: ({ denialLookup, denialCode }) => _jsx(TruncateTypography, { text: (denialLookup === null || denialLookup === void 0 ? void 0 : denialLookup.name) || denialCode || "-" }),
            },
        ];
    }, [t]);
};
