import { jsx as _jsx } from "react/jsx-runtime";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { GridProvider } from "../../../../shared/components";
import { useOptimaClaimsListQuery } from "../../../OptimaClaims/gql";
import { useOptimaClaimListContainerColumns } from "./useOptimaClaimListContainerColumns";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { useSiteSettingsGetQuery } from "../../../../shared/gql/queries";
export const OptimaClaimsListContainer = props => {
    var _a;
    const { filters, isProviderAdmin } = props;
    const { refetch } = useGridContext();
    useOnOptimaHaveNewNotification(() => {
        refetch({ first: 10 });
    });
    const { data } = useSiteSettingsGetQuery({
        fetchPolicy: "cache-first",
    });
    const defaultCurrency = (_a = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _a === void 0 ? void 0 : _a.defaultCurrency;
    const formattedCurrency = defaultCurrency ? " " + defaultCurrency : "";
    return (_jsx(GridProvider, { gridName: "optimaClaimsList", query: useOptimaClaimsListQuery, columns: useOptimaClaimListContainerColumns({ isProviderAdmin, formattedCurrency }), hasTableSetting: true, filterInput: filters, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsx(TableSettingComponent, {}), children: _jsx(TableGrid, {}) }) }));
};
