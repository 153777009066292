import { Box, CustomIcon, IconButton, Typography, useTheme } from "@toolkit/ui";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { ConsentStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";

type ConsentStatusViewProps = {
  onReset: () => void;
};

export const ConsentStatusView: FC<ConsentStatusViewProps> = ({ onReset: handleReset }) => {
  const { t } = useTranslation("provider");

  const theme = useTheme();
  const { consentStatus, patientName, handleResetStatus, onClose: handleClose } = useProfileContext();

  const handleReCreate = () => {
    handleResetStatus?.();
    handleReset();
    handleClose?.();
  };

  return (
    <Box
      p={1}
      sx={{
        height: 130,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {consentStatus === ConsentStatus.Approved ? (
        <>
          <Typography
            fontSize={theme.mixins.fonts.fontSize.xl}
            color={theme.palette.success.main}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
          >
            {t("Access Approved From")} {patientName || t("Patient")}
          </Typography>
          <IconButton onClick={handleResetStatus}>
            <CustomIcon icon='xAlert' />
          </IconButton>
        </>
      ) : (
        <>
          <Typography fontSize={theme.mixins.fonts.fontSize.xl} color='error' fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {t("Access Denied from")} {patientName || t("Patient")}
          </Typography>
          <IconButton onClick={handleReCreate}>
            <CustomIcon icon='xAlert' />
          </IconButton>
        </>
      )}
    </Box>
  );
};
