import { Doctor, UserUpdateInput } from "@health/queries/types";
import { doctorSeniorityOptionsMap } from "@health/enum-options";
import { IDoctorUpdateFormValues } from "../forms/DoctorUpdate/DoctorUpdateFormSchema";

export const convertDoctorToFormValues = (doctor: Doctor): IDoctorUpdateFormValues => {
  return {
    seniority: doctorSeniorityOptionsMap[doctor?.seniority!],
    bio: doctor?.bio!,
    photo: doctor?.user?.photo!,
  };
};

export const convertDoctorFormValuesToBackEndValues = (values: IDoctorUpdateFormValues): UserUpdateInput => {
  return {
    photo: values?.photo,
    doctorInfo: {
      seniority: values?.seniority?.value,
      bio: values?.bio,
    },
  };
};
