import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useCitiesAutocompleteQuery } from "./gql";
export const createCityAutocompleteOption = (city) => {
    return createAutocompleteOption({
        id: city === null || city === void 0 ? void 0 : city.id,
        name: city === null || city === void 0 ? void 0 : city.name,
        nameAr: city === null || city === void 0 ? void 0 : city.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getCitiesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "cities",
        query: useCitiesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
    });
};
