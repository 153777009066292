import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDoctorsAutocompleteQuery } from "./gql";
export const createDoctorAutocompleteOption = (doctor) => {
    var _a, _b;
    return createAutocompleteOption({
        id: doctor === null || doctor === void 0 ? void 0 : doctor.id,
        user: { id: (_a = doctor === null || doctor === void 0 ? void 0 : doctor.user) === null || _a === void 0 ? void 0 : _a.id, fullName: (_b = doctor === null || doctor === void 0 ? void 0 : doctor.user) === null || _b === void 0 ? void 0 : _b.fullName },
    }, "id", item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.user) === null || _a === void 0 ? void 0 : _a.fullName; });
};
export const getDoctorsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "doctors",
        query: useDoctorsAutocompleteQuery,
        labelBy: option => { var _a; return (_a = option === null || option === void 0 ? void 0 : option.user) === null || _a === void 0 ? void 0 : _a.fullName; },
        backendAccessor: "id",
        filterSearchKey: "name",
    });
};
