import { DepartmentMedicalServiceCountableEdge, Maybe } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTable, ShowButton } from "@toolkit/ui";
import React, { FC } from "react";
import { useDepartmentMedicalServicesColumns } from "./useDepartmentMedicalServicesColumns";

type DepartmentMedicalServicesProps = {
  medicalServices?: Maybe<DepartmentMedicalServiceCountableEdge[]>;
};

export const DepartmentMedicalServices: FC<DepartmentMedicalServicesProps> = props => {
  const { medicalServices } = props;

  const { t } = useTranslation("provider");

  return (
    <CustomDialog
      type={"info"}
      title={t("Department Medical Services")}
      button={<ShowButton disabled={!medicalServices || !medicalServices?.length} />}
    >
      <CustomTable
        data={medicalServices as DepartmentMedicalServiceCountableEdge[]}
        columns={useDepartmentMedicalServicesColumns()}
        scrollable
        hasFooter={false}
      />
    </CustomDialog>
  );
};
