import { OptimaClaimsListContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { isVendorAdmin } from "@/utils";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { OptimaClaimsRoutesBreadcrumb } from "../../constants/optima-claims-breadcrumbs";

export const ProviderOptimaClaimsListContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");
  const { userInfo } = useOidcUserProfile();

  useEffect(() => {
    setBreadCrumb({
      title: OptimaClaimsRoutesBreadcrumb(t),
    });
  }, []);
  const isProviderAdmin = isVendorAdmin(userInfo);

  return <OptimaClaimsListContainer isProviderAdmin={isProviderAdmin} />;
};
