import { downloadDocument, DownloadDocumentOptions } from "@/utils/downloadDocument";
import { useTranslation } from "@toolkit/i18n";
import { Button } from "@toolkit/ui";
import { useAuth } from "react-oidc-context";
import { useProfileContext } from "../../context/ProfileContext";
import { PatientInformationSection } from "./PatientInformationSection";

export const HealthDocuments = () => {
  const { t } = useTranslation("provider");
  const { loading, patientHealthDocuments, documentSecretKey } = useProfileContext();
  const { user } = useAuth();

  const options: DownloadDocumentOptions = {
    token: `Bearer ${user?.access_token}`,
    downloadKey: documentSecretKey,
  };

  const handleDownloadFile = (url: string) => async () => {
    downloadDocument(url, options);
  };
  return (
    <>
      {!loading && <PatientInformationSection title={t("Health Documents")} hasItems />}

      {patientHealthDocuments?.map(item => {
        return (
          <Button key={item?.id} variant='outlined' onClick={handleDownloadFile(item?.fileLink)}>
            {item?.name}
          </Button>
        );
      })}
    </>
  );
};
