import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const userWorkspaceNurseUpsertFormSchema = z.object({
  branch: zodSchema.branch,
  department: zodSchema.department,
  doctors: z.array(zodSchema.doctor).min(1),
});

export type IUserWorkspaceNurseUpsertFormValues = z.infer<typeof userWorkspaceNurseUpsertFormSchema>;

export const userWorkspaceNurseUpsertFormDefaultValues: Partial<IUserWorkspaceNurseUpsertFormValues> = {
  branch: undefined,
  department: undefined,
  doctors: [],
};
