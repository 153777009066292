import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MuiDrawer, ShowButton, Typography } from "@toolkit/ui";
import { useResubmissionDrawerStyles } from "./ResubmissionDrawerStyles";
import { ClaimGeneralInformation } from "../ClaimGeneralInformation/ClaimGeneralInformation";
import { ClaimTransactionDetails } from "../ClaimTransactionDetails/ClaimTransactionDetails";
import { ClaimEncounterDetails } from "../ClaimEncounterDetails/ClaimEncounterDetails";
import { OptimaClaimActivitiesList } from "../../../OptimaClaims/containers";
import { ResubmissionDrawerDetails } from "./ResubmissionDrawerDetails";
export const ResubmissionDrawer = props => {
    const { claim } = props;
    const { classes } = useResubmissionDrawerStyles();
    const { t } = useTranslation("domains");
    const { handleOpen, handleToggle, open } = useOpenState();
    return (_jsxs(_Fragment, { children: [!((claim === null || claim === void 0 ? void 0 : claim.submissionCycle) === "ORIGINAL") ? (_jsx(ShowButton, { onClick: handleOpen, disabled: (claim === null || claim === void 0 ? void 0 : claim.submissionCycle) === "ORIGINAL" })) : (_jsx(Box, { sx: {
                    width: "100%",
                    textAlign: "center",
                }, children: "-" })), _jsx(MuiDrawer, { anchor: 'right', open: open, onClose: handleToggle, PaperProps: {
                    className: classes.drawer,
                }, children: _jsxs(Box, { padding: 2, children: [_jsx(Typography, { className: classes.sectionTitle, children: t("General Information") }), _jsx(ClaimGeneralInformation, { claim: claim }), _jsx(Typography, { className: classes.sectionTitle, children: t("Transaction Details") }), _jsx(ClaimTransactionDetails, { claim: claim }), _jsx(Typography, { className: classes.sectionTitle, children: t("Encounter Details") }), _jsx(ClaimEncounterDetails, { claim: claim }), _jsx(Typography, { className: classes.sectionTitle, children: t("Resubmission Details") }), _jsx(ResubmissionDrawerDetails, { claim: claim }), _jsx(OptimaClaimActivitiesList, { id: claim === null || claim === void 0 ? void 0 : claim.id }), _jsx(Typography, { className: classes.sectionTitle, mt: 3, children: t("Comment:") }), _jsx(Typography, { className: classes.comments, children: (claim === null || claim === void 0 ? void 0 : claim.resubmissionComment) || "-" })] }) })] }));
};
