import { useReactiveVar } from "@apollo/client";
import { Coverage, Maybe } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import moment from "moment-timezone";
import { DoctorAppointmentAcceptMutation, useDoctorAppointmentAcceptMutation } from "pages/appointments/gql";
import { IFormattedTimeSlot } from "pages/appointments/types/types";
import { convertAppointmentTimeSlotToBackEndValue, groupTimeSlotsByTimeOfDay, isDateExpired } from "pages/appointments/utils";
import { useCallback, useEffect, useState } from "react";
import { NotificationItemAction } from "shared/components";
import { userWorkspaceDoctorVar } from "@/pages/UserWorkspace/vars";

type AppointmentAcceptType = {
  id: string;
  requestedTimeSlots?: Maybe<Maybe<string>[]>;
} & NotificationItemAction;

export const useAppointmentAccept = ({ id, requestedTimeSlots, handleNotificationActionComplete }: AppointmentAcceptType) => {
  const [patientShareAmount, setPatientShareAmount] = useState<number | undefined>();
  const [selectedDate, setSelectedDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<IFormattedTimeSlot>();

  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();
  const { open, handleClose, handleToggle } = useOpenState();

  const branchUserPreference = useReactiveVar(userWorkspaceDoctorVar);

  const requestedStartTime = requestedTimeSlots?.[0] && moment(JSON.parse(requestedTimeSlots?.[0])?.startTime).format("YYYY-MM-DD");

  const isExpiredDate = isDateExpired(selectedDate);

  const requestedTimeSlotsMapped = requestedTimeSlots?.map(item => {
    const time = JSON.parse(item!);
    return {
      startTime: time.startTime,
      endTime: time.endTime,
    };
  });

  const timeSlots = groupTimeSlotsByTimeOfDay(requestedTimeSlotsMapped);

  const [doctorAppointmentAccept, { loading: isSubmitting }] = useDoctorAppointmentAcceptMutation({
    onCompleted: (data: DoctorAppointmentAcceptMutation) => {
      const appointmentErrors = data?.doctorAppointmentAccept?.appointmentErrors;
      if (appointmentErrors?.length === 0) {
        succeeded(t("Appointment Accept Successfully"));
        handleNotificationActionComplete?.();
        handleClose();
        handleReset();
      } else {
        failed(formatMessageErrors(appointmentErrors));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.doctorAppointmentAccept?.appointment?.id, __typename: "Appointment" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onSubmit = () => {
    if (selectedTimeSlot && moment(requestedStartTime)) {
      setErrors(false);

      doctorAppointmentAccept({
        variables: {
          id,
          input: {
            suggestedTimeSlots: [convertAppointmentTimeSlotToBackEndValue(selectedTimeSlot)],
            paymentInfo: {
              integrationPatientShare: patientShareAmount!,
            },
            branchId: branchUserPreference?.branch?.id,
          },
        },
      });
    } else {
      setErrors(true);
    }
  };

  const handleReset = () => {
    setPatientShareAmount(undefined);
    setErrors(false);
  };

  const handleSelectTimeSlot = (timeSlot: IFormattedTimeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handlePatientShareAmountChange = useCallback((value?: number, coverage?: Coverage | null) => {
    setPatientShareAmount(value);
    if (value || (0 === value && coverage === Coverage.FullyCovered)) {
      setErrors(false);
    } else {
      setErrors(true);
    }
  }, []);

  const handleChangeDate = date => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (requestedStartTime) {
      setSelectedDate(requestedStartTime);
    }
  }, [requestedStartTime]);

  return {
    t,
    errors,
    selectedTimeSlot,
    patientShareAmount,
    selectedDate,
    timeSlots,
    handleToggle,
    open,
    isExpiredDate,
    onSubmit,
    isSubmitting,
    handlePatientShareAmountChange,
    handleSelectTimeSlot,
    handleChangeDate,
  };
};
