import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const userWorkspaceDoctorUpsertFormSchema = z.object({
  city: zodSchema.city,
  branch: zodSchema.branch,
});

export type IUserWorkspaceDoctorUpsertFormValues = z.infer<typeof userWorkspaceDoctorUpsertFormSchema>;

export const userWorkspaceDoctorUpsertFormDefaultValues: Partial<IUserWorkspaceDoctorUpsertFormValues> = {
  city: undefined,
  branch: undefined,
};
