import { jsx as _jsx } from "react/jsx-runtime";
import { Box, RejectIcon, SandTimerIcon } from "@toolkit/ui";
import { CustomIcon } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useRequestListKpisStyles } from "./RequestListKpisStyles";
import { KPIItem } from "../KpiItem/KPIItem";
export const RequestListKpis = props => {
    const { totalCounts, selectedKpiMode, handleKpiModeChange } = props;
    const { classes } = useRequestListKpisStyles({});
    const { t } = useTranslation("domains");
    const handleCardClick = (mode) => () => handleKpiModeChange(mode);
    const subtitleFormat = "{{count}} requests";
    const kpiItems = [
        {
            title: t("Show all requests"),
            subtitle: t(subtitleFormat, { count: (totalCounts === null || totalCounts === void 0 ? void 0 : totalCounts.all) || 0 }),
            icon: _jsx(CustomIcon, { margin: "0 0 0 2px", icon: 'insurance' }),
            type: "all",
            color: "success",
            isSelected: selectedKpiMode.type === "all",
        },
        {
            title: t("Today's Rejected Requests"),
            subtitle: t(subtitleFormat, { count: (totalCounts === null || totalCounts === void 0 ? void 0 : totalCounts.rejected) || 0 }),
            icon: _jsx(RejectIcon, {}),
            type: "rejected",
            color: "error",
            isSelected: selectedKpiMode.type === "rejected",
        },
        {
            title: t("Today's Requests without claim"),
            subtitle: t(subtitleFormat, { count: (totalCounts === null || totalCounts === void 0 ? void 0 : totalCounts.withoutClaim) || 0 }),
            icon: _jsx(SandTimerIcon, {}),
            type: "withoutClaim",
            color: "warning",
            isSelected: selectedKpiMode.type === "withoutClaim",
        },
    ];
    return (_jsx(Box, { className: classes.container, children: kpiItems.map((item, index) => (_jsx(KPIItem, { title: item.title, subtitle: item.subtitle, icon: item.icon, onClick: handleCardClick(item.type), color: item.color, isSelected: item === null || item === void 0 ? void 0 : item.isSelected }, index))) }));
};
