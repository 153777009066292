import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { Qualification } from "@health/queries/types";

export const useDoctorQualificationsColumns = (): CustomTableColumnProps<Qualification>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "issuer",
        header: t("Display"),
        accessor: ({ issuer }) => issuer || "-",
      },
    ];
  }, [t]);
};
