import { useOptimaPriorRequestKpIsTotalCountsQuery } from "../../../OptimaPriorRequests/gql";
import { optimaRequestKpi } from "../../../OptimaPriorRequests/utils";
import { useState } from "react";
export const useOptimaPriorRequestListKpiController = ({ activeFilters }) => {
    var _a, _b, _c;
    const [selectedKpiMode, setSelectedKpiMode] = useState(optimaRequestKpi.all);
    const { data, loading } = useOptimaPriorRequestKpIsTotalCountsQuery({
        variables: {
            filter: activeFilters || {},
        },
    });
    const hasFilter = (selectedKpiMode === null || selectedKpiMode === void 0 ? void 0 : selectedKpiMode.type) === "all";
    const totalCounts = {
        all: ((_a = data === null || data === void 0 ? void 0 : data.optimaPriorRequests) === null || _a === void 0 ? void 0 : _a.totalCount) || 0,
        rejected: ((_b = data === null || data === void 0 ? void 0 : data.optimaPriorRequestsRejectedAuth) === null || _b === void 0 ? void 0 : _b.totalCount) || 0,
        withoutClaim: ((_c = data === null || data === void 0 ? void 0 : data.optimaPriorRequestsWithoutClaim) === null || _c === void 0 ? void 0 : _c.totalCount) || 0,
    };
    const handleKpiModeChange = (mode) => {
        setSelectedKpiMode(optimaRequestKpi[mode]);
    };
    return {
        selectedKpiMode,
        handleKpiModeChange,
        totalCounts,
        isCountsLoading: loading,
        hasFilter,
    };
};
