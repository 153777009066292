import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useSiteSettingsGetQuery } from "../../../../shared/gql/queries";
import { toTitleCase } from "@toolkit/core";
export const useOptimaClaimActivitiesColumns = () => {
    var _a;
    const { t } = useTranslation("domains");
    const { data } = useSiteSettingsGetQuery({
        fetchPolicy: "cache-first",
    });
    const defaultCurrency = ((_a = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _a === void 0 ? void 0 : _a.defaultCurrency) || "";
    return useMemo(() => {
        return [
            {
                key: "code",
                header: t("Code"),
                accessor: ({ codeLookup, code }) => toTitleCase((codeLookup === null || codeLookup === void 0 ? void 0 : codeLookup.name) || code || "-"),
            },
            {
                key: "type",
                header: t("Type"),
                accessor: ({ typeLookup }) => (typeLookup === null || typeLookup === void 0 ? void 0 : typeLookup.name) || "-",
            },
            {
                key: "quantity",
                header: t("Quantity"),
                accessor: "quantity",
            },
            {
                key: "Amount",
                header: t("Amount"),
                accessor: ({ net }) => (net ? net + " " + defaultCurrency : "-"),
            },
        ];
    }, [t]);
};
