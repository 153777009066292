import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import { useMemo } from "react";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, format, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { BACKEND_DATE_FORMAT, toTitleCase } from "@toolkit/core";
import { transactionSourceTypeOptionsMap, transactionStatusTypeOptionsMap } from "@health/enum-options";
import { RemittanceDrawer } from "../../../OptimaClaims/Components/RemittanceDrawer/RemittanceDrawer";
import { formatFilterDateForBackend, getOptimaAuthorizationStatusColor } from "../../../../utils";
import { ClaimActivitiesDrawer } from "../../../OptimaClaims/Components/ClaimActivitiesDrawer/ClaimActivitiesDrawer";
import { ResubmissionDrawer } from "../../../OptimaClaims/Components/ResubmissionDrawer/ResubmissionDrawer";
import { GridCellText } from "../../../../components";
export const useOptimaClaimListContainerColumns = (props) => {
    const { isProviderAdmin, formattedCurrency } = props;
    const { t } = useTranslation("domains");
    const theme = useTheme();
    return useMemo(() => {
        return [
            {
                key: "transactionId",
                header: t("Transaction Id"),
                accessor: ({ transactionId }) => _jsx(GridCellText, { text: transactionId }),
                filter: {
                    type: "string",
                    name: "transactionId",
                },
            },
            {
                key: "transactionDate",
                header: t("Transaction Date"),
                accessor: ({ transactionDate }) => (_jsx(Box, { sx: { width: "max-content" }, children: transactionDate && format(transactionDate, BACKEND_DATE_FORMAT, i18n.language) })),
            },
            {
                key: "authorizationStatus",
                header: t("Status"),
                accessor: ({ remittance }) => {
                    var _a;
                    return (_jsx(Typography, { fontWeight: theme.mixins.fonts.fontWeight.medium, color: getOptimaAuthorizationStatusColor((remittance === null || remittance === void 0 ? void 0 : remittance.status) || "", theme), children: ((_a = transactionStatusTypeOptionsMap[(remittance === null || remittance === void 0 ? void 0 : remittance.status) || ""]) === null || _a === void 0 ? void 0 : _a.label) || t("Pending") }));
                },
            },
            {
                key: "transactionType",
                header: t("Transaction Type"),
                accessor: ({ transactionType }) => transactionType || "-",
            },
            {
                key: "provider",
                header: t("Provider"),
                accessor: ({ provider }) => _jsx(TruncateTypography, { text: toTitleCase((provider === null || provider === void 0 ? void 0 : provider.name) || "-") }),
            },
            {
                key: "branch",
                header: t("Branch"),
                accessor: ({ Branch }) => pickLocalizedValue(Branch === null || Branch === void 0 ? void 0 : Branch.name, Branch === null || Branch === void 0 ? void 0 : Branch.nameAr) || "-",
                hideFromPreferencesAndTable: !isProviderAdmin,
            },
            {
                key: "patientId",
                header: t("Patient Id"),
                accessor: ({ patientId }) => _jsx(GridCellText, { text: patientId }),
                filter: {
                    type: "string",
                    name: "patientId",
                },
            },
            {
                key: "memberId",
                header: t("Member Id"),
                accessor: ({ memberId }) => _jsx(GridCellText, { text: memberId }),
                filter: {
                    type: "string",
                    name: "memberId",
                },
            },
            {
                key: "emiratesIDNumber",
                header: t("Emirate ID"),
                accessor: ({ emiratesIDNumber }) => _jsx(GridCellText, { text: emiratesIDNumber }),
                filter: {
                    type: "string",
                    name: "emiratesIDNumber",
                },
            },
            {
                key: "payer",
                header: t("Payer"),
                accessor: ({ payer }) => _jsx(TruncateTypography, { text: toTitleCase((payer === null || payer === void 0 ? void 0 : payer.name) || "-") }),
            },
            {
                key: "payerId",
                header: t("Payer Id"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "string",
                    name: "payerId",
                },
            },
            {
                key: "receiver",
                header: t("Receiver"),
                accessor: ({ receiver }) => _jsx(TruncateTypography, { text: toTitleCase((receiver === null || receiver === void 0 ? void 0 : receiver.name) || "-") }),
            },
            {
                key: "receiverId",
                header: t("Receiver Id"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "string",
                    name: "receiverId",
                },
            },
            {
                key: "encounterStartType",
                header: t("Encounter Start Type"),
                accessor: ({ encounterStartType, encounterStartTypeLookup }) => (encounterStartTypeLookup === null || encounterStartTypeLookup === void 0 ? void 0 : encounterStartTypeLookup.name) || encounterStartType || "-",
            },
            {
                key: "encounterStart",
                header: t("Encounter Start Date"),
                accessor: ({ encounterStart }) => (_jsx(GridCellText, { text: encounterStart ? format(encounterStart, "DD MMMM YYYY | HH:mm A", i18n.language) : "-" })),
            },
            {
                key: "encounterEndType",
                header: t("encounter End Type"),
                accessor: ({ encounterEndType, encounterEndTypeLookup }) => (encounterEndTypeLookup === null || encounterEndTypeLookup === void 0 ? void 0 : encounterEndTypeLookup.name) || encounterEndType || "-",
            },
            {
                key: "encounterEnd",
                header: t("Encounter End Date"),
                accessor: ({ encounterEnd }) => (_jsx(GridCellText, { text: encounterEnd ? format(encounterEnd, "DD MMMM YYYY | HH:mm A", i18n.language) : "-" })),
            },
            {
                key: "gross",
                header: t("Gross"),
                accessor: ({ gross }) => (gross ? gross + formattedCurrency : "-"),
            },
            {
                key: "net",
                header: t("Net"),
                accessor: ({ net }) => (net ? net + formattedCurrency : "-"),
            },
            {
                key: "source",
                header: t("Source"),
                accessor: ({ source }) => transactionSourceTypeOptionsMap[source].label,
            },
            {
                key: "claimActivities",
                header: t("Activities"),
                accessor: claim => _jsx(ClaimActivitiesDrawer, { claim: claim }),
            },
            {
                key: "Remittance",
                header: t("Remittance"),
                accessor: claim => _jsx(RemittanceDrawer, { claim: claim }),
            },
            {
                key: "Resubmission",
                header: t("Resubmission"),
                accessor: claim => _jsx(ResubmissionDrawer, { claim: claim }),
            },
            {
                key: "transactionDateInputGte",
                header: t("Transaction Date From"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "transactionDateInput.gte",
                },
            },
            {
                key: "transactionDateInputLte",
                header: t("Transaction Date To"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "transactionDateInput.lte",
                    getValueForBackend: formatFilterDateForBackend,
                },
            },
            {
                key: "encounterStartDateInputGte",
                header: t("Encounter Start Date From"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "encounterStartDateInput.gte",
                },
            },
            {
                key: "encounterStartDateInputLte",
                header: t("Encounter Start Date To"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "encounterStartDateInput.lte",
                    getValueForBackend: formatFilterDateForBackend,
                },
            },
            {
                key: "encounterEndDateInputGte",
                header: t("Encounter End Date From"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "encounterEndDateInput.gte",
                },
            },
            {
                key: "encounterEndDateInputLte",
                header: t("Encounter End Date To"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "encounterEndDateInput.lte",
                    getValueForBackend: formatFilterDateForBackend,
                },
            },
        ];
    }, [t]);
};
