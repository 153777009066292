import { User } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, PageWrapper, useBreadCrumbs } from "@toolkit/ui";
import { branchEditBreadCrumb, branchListBreadCrumb, branchUserListBreadCrumb } from "pages/HealthProviderManagement/Branches/constant";
import { useGetBranchUsersQuery } from "pages/HealthProviderManagement/Branches/gql";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetBranchesUsersColumns } from "./getBranchUsersColumns";

export const BranchUsersScreen: FC = () => {
  const { branchId } = useParams();
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const { data, loading: loadingBranchUsers } = useGetBranchUsersQuery({
    variables: {
      id: String(branchId),
    },
  });
  const users = data?.branch?.users as User[];
  const handleSortColumn = () => {};

  useEffect(() => {
    setBreadCrumb({
      title: "Branches Management",
      values: [branchListBreadCrumb(t), branchEditBreadCrumb(String(branchId), data?.branch?.name, t), branchUserListBreadCrumb(t)],
    });
  }, [branchId, data?.branch?.name, t]);

  return (
    <PageWrapper>
      <CustomTable
        data={users}
        columns={useGetBranchesUsersColumns()}
        pageSize={10}
        isLoading={loadingBranchUsers}
        pageIndex={10}
        onSortColumn={handleSortColumn}
        pagesCount={10}
      />
    </PageWrapper>
  );
};
