import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { optimaClaimsRoutes } from "@/pages/Optima/OptimaClaims/constants";
import { optimaPriorRequestsRoutes } from "@/pages/Optima/OptimaPriorRequests/constants";

export const optimaRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-routes",
    text: t("Optima", { ns: "provider" }),
    icon: <ListViewIcon />,
    hidden: !hasPermission(PermissionEnum.ViewTransaction),
    isProhibited: !hasPermission(PermissionEnum.ViewTransaction),
    subItems: [optimaPriorRequestsRoutes({ navigate, t }), optimaClaimsRoutes({ navigate, t })],
  };
};
