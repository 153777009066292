import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MuiDrawer, ShowButton, Typography } from "@toolkit/ui";
import { usePriorAuthorizationDrawerStyles } from "./PriorAuthorizationDrawerStyle";
import { PriorAuthorizationDrawerSummary } from "../PriorAuthorizationDrawerSummary/PriorAuthorizationDrawerSummary";
import { PriorAuthorizationDrawerDetails } from "../PriorAuthorizationDrawerDetails/PriorAuthorizationDrawerDetails";
import { PriorAuthorizationActivitiesList } from "../../../OptimaPriorRequests/containers/PriorAuthorizationActivitiesList/PriorAuthorizationActivitiesList";
export const PriorAuthorizationDrawer = props => {
    const { request } = props;
    const { priorAuthorization } = request;
    const { classes } = usePriorAuthorizationDrawerStyles();
    const { t } = useTranslation("domains");
    const { handleOpen, handleToggle, open } = useOpenState();
    return (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.id) ? (_jsxs(_Fragment, { children: [_jsx(ShowButton, { onClick: handleOpen }), _jsx(MuiDrawer, { anchor: 'right', open: open, onClose: handleToggle, PaperProps: {
                    className: classes.drawer,
                }, children: _jsxs(Box, { padding: 2, children: [_jsx(Typography, { className: classes.sectionTitle, children: t("Authorization Summary") }), _jsx(Box, { className: classes.sectionContainer, children: _jsx(PriorAuthorizationDrawerSummary, { request: request }) }), _jsx(Typography, { className: classes.sectionTitle, children: t("Authorization Details") }), _jsx(Box, { className: classes.sectionContainer, children: _jsx(PriorAuthorizationDrawerDetails, { request: request }) }), _jsx(PriorAuthorizationActivitiesList, { id: priorAuthorization.id }), _jsx(Typography, { className: classes.sectionTitle, mt: 3, children: t("Comments") }), _jsx(Typography, { className: classes.comments, children: (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.comments) || "-" })] }) })] })) : (_jsx(Typography, { className: classes.pending, children: t("Pending") }));
};
