import { Typography } from "@toolkit/ui";
import { useEffect, useMemo } from "react";
import { useOpenState } from "@toolkit/core";
import { useReactiveVar } from "@apollo/client";
import { removeProviderLocalStorageItem, setProviderLocalStorageItem } from "@/utils";
import { DoctorNurseAssignment } from "@health/queries/types";
import { useNurseDoctorListQuery, useNurseDoctorsAssignMutation } from "../../gql";
import { userWorkspaceNurseVar } from "../../vars";
import { getNurseCurrentWorkspaceMessage, updateUserWorkspaceNurseData } from "../../others";
import { UserWorkspaceNurseUpsertModal } from "../../modals/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertModal";
import { IUserWorkspaceNurseUpsertFormEvent } from "../../forms/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertForm";
import { useUserWorkspaceNurseStyle } from "./useUserWorkspaceNurseStyle";

export const UserWorkspaceNurse = () => {
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes } = useUserWorkspaceNurseStyle();

  const nurseCurrentWorkspace = useReactiveVar(userWorkspaceNurseVar);

  const assignedDoctorsLength = nurseCurrentWorkspace?.doctors?.length || 0;

  const nurseCurrentWorkspaceMessage = getNurseCurrentWorkspaceMessage(assignedDoctorsLength);

  const { data, loading } = useNurseDoctorListQuery();

  const doctorNurseAssignments = useMemo(() => {
    return (data?.doctorNurseAssignments?.edges?.map(item => item?.node) || []) as DoctorNurseAssignment[];
  }, [data]);

  const [assignNurseDoctors, { loading: isSubmitting }] = useNurseDoctorsAssignMutation();

  const onNurseDoctorsAssignModalChange = (event: IUserWorkspaceNurseUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const { branch, department, doctors } = event.payload.values;

      const nurseCurrentWorkspaceData = {
        branch: branch?.value,
        department: department?.value,
        doctors: doctors?.map(item => item?.value!),
      };

      assignNurseDoctors({
        variables: {
          input: nurseCurrentWorkspaceData?.doctors?.map(item => ({
            departmentId: nurseCurrentWorkspaceData?.department?.id,
            doctorId: item?.id,
          })),
        },
        onCompleted: mutationData => {
          if (mutationData?.saveDoctorNurseAssignment?.length) {
            userWorkspaceNurseVar(nurseCurrentWorkspaceData);
            setProviderLocalStorageItem("UserWorkspaceNurse", nurseCurrentWorkspaceData);
            handleClose();
          }
        },
      });
    }
  };

  useEffect(() => {
    if (loading) return;

    if (doctorNurseAssignments?.length) {
      updateUserWorkspaceNurseData(doctorNurseAssignments, nurseCurrentWorkspace);
    } else {
      userWorkspaceNurseVar(null);
      removeProviderLocalStorageItem("UserWorkspaceNurse");

      if (!nurseCurrentWorkspace) {
        return handleOpen();
      }
    }
  }, [doctorNurseAssignments?.length, loading]);

  return (
    <>
      <Typography className={classes.message} onClick={handleOpen}>
        {nurseCurrentWorkspaceMessage}
      </Typography>

      <UserWorkspaceNurseUpsertModal
        isOpen={open}
        isLoading={isSubmitting}
        hasClose={!!nurseCurrentWorkspace}
        onClose={handleClose}
        onChange={onNurseDoctorsAssignModalChange}
      />
    </>
  );
};
