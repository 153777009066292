import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable sonarjs/cognitive-complexity */
import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { useSiteSettingsGetQuery } from "../../../../shared/gql/queries";
import { toTitleCase } from "@toolkit/core";
export const usePriorAuthorizationActivitiesListContainerColumns = () => {
    var _a;
    const { t } = useTranslation("domains");
    const { data } = useSiteSettingsGetQuery({
        fetchPolicy: "cache-first",
    });
    const defaultCurrency = ((_a = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _a === void 0 ? void 0 : _a.defaultCurrency) || "";
    const theme = useTheme();
    const getColor = (color) => { var _a, _b; return (_b = (_a = theme.palette) === null || _a === void 0 ? void 0 : _a[color]) === null || _b === void 0 ? void 0 : _b.main; };
    return useMemo(() => {
        return [
            {
                key: "code",
                header: t("Code"),
                accessor: ({ code, codeLookup }) => _jsx(TruncateTypography, { text: toTitleCase((codeLookup === null || codeLookup === void 0 ? void 0 : codeLookup.name) || code || "-") }),
            },
            {
                key: "type",
                header: t("Type"),
                accessor: ({ type, typeLookup }) => (typeLookup === null || typeLookup === void 0 ? void 0 : typeLookup.name) || type || "-",
            },
            {
                key: "quantity",
                header: t("Quantity"),
                accessor: "quantity",
            },
            {
                key: "netAmount",
                header: t("Net Amount"),
                accessor: ({ net }) => (net ? net + " " + defaultCurrency : "-"),
            },
            {
                key: "paymentAmount",
                header: t("Payment Amount"),
                accessor: ({ paymentAmount }) => (paymentAmount ? paymentAmount + " " + defaultCurrency : "-"),
            },
            {
                key: "patientShare",
                header: t("Patient share"),
                accessor: ({ patientShare }) => (patientShare ? patientShare + " " + defaultCurrency : "-"),
            },
            {
                key: "activityStatus",
                header: t("status"),
                accessor: ({ denialCode }) => denialCode ? (_jsx(Typography, { color: getColor("error"), children: t("Rejected") })) : (_jsx(Typography, { color: getColor("success"), children: t("Approved") })),
            },
            {
                key: "isClaimed",
                header: "Claimed?",
                accessor: ({ claimed, denialCode }) => (_jsx(Box, { sx: { width: "100%", textAlign: "center" }, children: (denialCode ? "-" : false) ||
                        (claimed ? (_jsx(Typography, { color: getColor("success"), children: t("Yes") })) : (_jsx(Typography, { color: getColor("warning"), children: "No" }))) })),
            },
            {
                key: "denialCode",
                header: t("Denial Code"),
                accessor: ({ denialCode, denialLookup }) => _jsx(TruncateTypography, { text: (denialLookup === null || denialLookup === void 0 ? void 0 : denialLookup.name) || denialCode || "-" }),
            },
        ];
    }, [t]);
};
