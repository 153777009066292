import { Box, Typography } from "@toolkit/ui";
import React, { FC } from "react";

export const CardContent: FC<{ title: string; subTitle?: string }> = ({ title, subTitle }) => {
  return (
    <Box m={1}>
      <Typography variant='bold-sm'>{title}: </Typography>
      {subTitle && <Typography variant='regular-sm'>{subTitle}</Typography>}
    </Box>
  );
};
