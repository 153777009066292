import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { format, ValueWithLabel } from "@toolkit/ui";
import { usePriorAuthorizationDrawerStyles } from "../PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { useSiteSettingsGetQuery } from "../../../../shared/gql/queries";
export const PriorAuthorizationDrawerDetails = props => {
    var _a;
    const { request } = props;
    const { priorAuthorization } = request;
    const { classes } = usePriorAuthorizationDrawerStyles();
    const { t } = useTranslation("domains");
    const { data } = useSiteSettingsGetQuery({
        fetchPolicy: "cache-first",
    });
    const defaultCurrency = ((_a = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _a === void 0 ? void 0 : _a.defaultCurrency) || "";
    return (_jsxs(_Fragment, { children: [_jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Start Date"), value: (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.start) ? format(priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.start, DATE_FORMAT_FOR_TABLE_LONG) : "-" }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("End Date"), value: (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.end) ? format(priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.end, DATE_FORMAT_FOR_TABLE_LONG) : "-" }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Limit"), value: (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.limit) ? (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.limit) + " " + defaultCurrency : "-" }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Result"), value: (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.result) || "-" })] }));
};
