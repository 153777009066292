import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";

export const doctorUpdateFormSchema = z.object({
  seniority: zodEnumSchema.doctorSeniority,
  bio: z.string().min(1),
  photo: z.string().nullish(),
});

export type IDoctorUpdateFormValues = z.infer<typeof doctorUpdateFormSchema>;

export const doctorUpdateFormDefaultValues: Partial<IDoctorUpdateFormValues> = {
  seniority: undefined,
  bio: undefined,
  photo: undefined,
};
