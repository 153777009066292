import React, { FC } from "react";
import { Grid, WaveSurferAudio } from "@toolkit/ui";
import { IPatientHealthParameterCardEvent, PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { usePatientHealthParameters } from "../../hooks";
import { PatientHealthParameterHistoryModalApi } from "../../modals";

type PatientHealthParameterAudioProps = {
  visitId?: string;
  patientId?: string;
  code: string;
  token: string;
};

export const PatientHealthParameterAudio: FC<PatientHealthParameterAudioProps> = props => {
  const { visitId, patientId, code, token } = props;

  const { data, loading } = usePatientHealthParameters({
    first: 1,
    visitId,
    patientId,
    token,
    codes: [code],
  });

  const patientHealthParameter = data?.[0];
  const patientHealthParameterAudioFile = patientHealthParameter?.valueAudioFile;

  const onPatientHealthParameterCardChange = (event: IPatientHealthParameterCardEvent) => {
    if (event.type === "EXPAND_CLICK") {
      PatientHealthParameterHistoryModalApi.open({
        type: "AUDIO",
        title: patientHealthParameter?.healthParameter?.display!,
        patientId,
        token,
        codes: [code],
      });
    }
  };

  return (
    <PatientHealthParameterCard
      code={code}
      token={token}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={patientHealthParameterAudioFile ? !visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {patientHealthParameterAudioFile && (
        <Grid item xs={4}>
          <WaveSurferAudio url={patientHealthParameterAudioFile} />
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
