import { AppointmentSortingField, H_OrderDirection } from "@health/queries/types";
import {
  ActiveFilters,
  Box,
  CustomToggleButton,
  FormControlLabel,
  PageWrapper,
  StaticFilterDialog,
  TableGrid,
  useTheme,
} from "@toolkit/ui";
import { OngoingVisit } from "pages/appointments/components";
import { useAppointmentsPageWrapper } from "pages/appointments/containers/Appointments/useAppointmentsPageWrapper";
import { useAppointmentsQuery } from "pages/appointments/gql";
import { AppointmentTabsStatus, mapTabStatesToFilterInput } from "pages/appointments/utils";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { PageTabs, PageTabsStatusType, PageTabsType } from "shared/components/PageTabs";
import { useAppointmentsColumnsContainer } from "./useAppointmentsColumnsContainer";
import { useAppointmentsContainer } from "./useAppointmentsContainer";
import { isTabsCountVisible } from "pages/appointments/constants";

export const AppointmentsContainer: FC = () => {
  const {
    t,
    status,
    tabsWithCounts,
    isSelfOnlyAppointments,
    handleChangeTab,
    handleUpdateCount,
    handleSelfOnlyAppointmentsChange,
    skipCall,
    filters,
  } = useAppointmentsContainer();

  return (
    <GridProvider
      gridName='appointments'
      columns={useAppointmentsColumnsContainer(status, isSelfOnlyAppointments)}
      query={useAppointmentsQuery}
      hideFilterInput
      filterInput={{
        selfOnly: isSelfOnlyAppointments,
        ...(status !== AppointmentTabsStatus.OngoingVisit && mapTabStatesToFilterInput(status)),
        ...filters,
      }}
      hasSavedInput
      variables={{
        includeCancellationReason: status === AppointmentTabsStatus.Cancelled,
        filter: {
          selfOnly: isSelfOnlyAppointments,
          ...(status !== AppointmentTabsStatus.OngoingVisit && mapTabStatesToFilterInput(status)),
          ...filters,
        },
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: AppointmentSortingField.Created,
        },
      }}
      skipCall={status === AppointmentTabsStatus.OngoingVisit || skipCall}
      tableAction={{
        withoutDataMessage:
          status === AppointmentTabsStatus.PendingConfirmation ? t("No Pending Appointments") : t("There are no appointments"),
      }}
    >
      <AppointmentsPageWrapper
        status={status}
        onChangeTab={handleChangeTab}
        tabs={tabsWithCounts}
        isSelfOnlyAppointments={isSelfOnlyAppointments}
        onUpdateCount={handleUpdateCount}
        onSelfOnlyAppointmentsChange={handleSelfOnlyAppointmentsChange}
      />
    </GridProvider>
  );
};

type AppointmentsPageWrapperProps = {
  status: PageTabsStatusType;
  onChangeTab: (status: PageTabsStatusType) => void;
  tabs: PageTabsType;
  isSelfOnlyAppointments: boolean;
  onUpdateCount: (input) => void;
  onSelfOnlyAppointmentsChange?: (value: boolean) => void;
};

const AppointmentsPageWrapper: FC<AppointmentsPageWrapperProps> = props => {
  const { status, onChangeTab, tabs, isSelfOnlyAppointments, onUpdateCount, onSelfOnlyAppointmentsChange } = props;

  const { t, isVendorAdmin, isLoading, activeFiltersProps, staticFilterDialogProps, handleTab, handleToggleOnlyMyAppointments } =
    useAppointmentsPageWrapper({
      isSelfOnlyAppointments,
      onChangeTab,
      onUpdateCount,
      onSelfOnlyAppointmentsChange,
    });
  const theme = useTheme();
  return (
    <PageWrapper
      filters={<ActiveFilters {...(activeFiltersProps as any)} />}
      end={
        <Box display='flex' alignItems='center' height={60}>
          {isVendorAdmin && (
            <FormControlLabel
              sx={{ minWidth: 120, marginInline: "5px", fontSize: theme.mixins.fonts.fontSize.xs }}
              control={
                <CustomToggleButton
                  value={isSelfOnlyAppointments}
                  defaultChecked={isSelfOnlyAppointments}
                  onChange={handleToggleOnlyMyAppointments}
                />
              }
              label={t("Me Only")}
            />
          )}
          <StaticFilterDialog {...staticFilterDialogProps} filters={activeFiltersProps?.filters!} />
        </Box>
      }
      headerComponent={
        <PageTabs tabs={tabs} disabled={isLoading} selected={status} onChangeTab={handleTab} showTabCount={isTabsCountVisible} />
      }
    >
      {status === AppointmentTabsStatus.OngoingVisit ? <OngoingVisit isSelfOnlyAppointments={isSelfOnlyAppointments} /> : <TableGrid />}
    </PageWrapper>
  );
};
