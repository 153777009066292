import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useDepartmentCreateMutation } from "../../gql";
import { useSetDepartmentsBreadcrumbs } from "../../hooks";
import { convertDepartmentCreateFormValuesToBackEndValues } from "../../others";
import { departmentsPaths } from "../../constants";
import { DepartmentUpsertForm, IDepartmentUpsertFormEvent } from "../../forms/DepartmentUpsert/DepartmentUpsertForm";

export const DepartmentCreateContainer = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createDepartment, { loading: isSubmitting }] = useDepartmentCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.vendorDepartmentCreate?.department?.id) {
        succeeded(t("Department created successfully"));
        navigate(departmentsPaths.list.fullPath);
      } else {
        failed(t("Department creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDepartmentUpsertFormChange = (event: IDepartmentUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertDepartmentCreateFormValuesToBackEndValues(event.payload.values);

      createDepartment({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetDepartmentsBreadcrumbs("CREATE");

  return <DepartmentUpsertForm buttonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onDepartmentUpsertFormChange} />;
};
