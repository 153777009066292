import { Department, VendorDepartmentCreateInput, VendorDepartmentUpdateInput } from "@health/queries/types";
import { createBranchAutocompleteOption } from "@health/autocompletes";
import { IDepartmentUpsertFormValues } from "../forms/DepartmentUpsert/DepartmentUpsertFormSchema";

export const convertDepartmentToFormValues = (department: Department): IDepartmentUpsertFormValues => {
  return {
    name: department?.name,
    code: department?.code,
    directions: department?.directions,
    branch: createBranchAutocompleteOption(department?.branch),
    medicalServices: department?.medicalServices?.edges?.length ? department?.medicalServices?.edges?.map(item => item?.node?.code) : [],
    description: department?.description,
  };
};

export const convertDepartmentFormValuesToBackEndValues = (values: IDepartmentUpsertFormValues) => {
  return {
    name: values?.name,
    code: values?.code,
    directions: values?.directions,
    medicalServices: values?.medicalServices,
    description: values?.description,
  };
};

export const convertDepartmentCreateFormValuesToBackEndValues = (values: IDepartmentUpsertFormValues): VendorDepartmentCreateInput => {
  return {
    ...convertDepartmentFormValuesToBackEndValues(values),
    branch: values?.branch?.value?.id,
  };
};

export const convertDepartmentUpdateFormValuesToBackEndValues = (values: IDepartmentUpsertFormValues): VendorDepartmentUpdateInput => {
  return convertDepartmentFormValuesToBackEndValues(values);
};
