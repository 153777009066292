import { CustomDialog, CustomTable, ShowButton } from "@toolkit/ui";
import { useDoctorSpecializationsColumns } from "./useDoctorSpecializationsColumns";
import { useTranslation } from "@toolkit/i18n";
import { DoctorSpecialization } from "@health/queries/types";
import React, { FC } from "react";
import { IDoctorListNode } from "@/pages/Doctors/types";

type DoctorSpecializationsProps = {
  specializations: IDoctorListNode["specializations"];
};

export const DoctorSpecializations: FC<DoctorSpecializationsProps> = props => {
  const { specializations } = props;

  const { t } = useTranslation("provider");

  return (
    <CustomDialog
      type={"info"}
      title={t("Doctor's Specializations")}
      button={<ShowButton disabled={!specializations || !specializations?.length} />}
    >
      <CustomTable
        data={specializations as DoctorSpecialization[]}
        columns={useDoctorSpecializationsColumns()}
        scrollable
        hasFooter={false}
      />
    </CustomDialog>
  );
};
