import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CustomTable, Typography } from "@toolkit/ui";
import { usePriorAuthorizationActivitiesList } from "./usePriorAuthorizationActivitiesList.hook";
import { usePriorAuthorizationDrawerStyles } from "../../../OptimaPriorRequests/components/PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
export const PriorAuthorizationActivitiesList = ({ id }) => {
    const { t, tableData, hasNextPage, hasPreviousPage, isLoading, handleGotoNext, handleGoToPrevious, pageSize, columns, totalCount } = usePriorAuthorizationActivitiesList(id);
    const { classes } = usePriorAuthorizationDrawerStyles();
    return (_jsxs(Box, { children: [_jsx(Box, { className: classes.claimsActivityContainer, children: _jsx(Typography, { className: classes.sectionTitle, mt: 4, mb: 2, children: t("Authorization Activities: ({{totalCount}})", { totalCount }) }) }), _jsx(CustomTable, { isLoading: isLoading, data: tableData, columns: columns, pageSize: pageSize, pageIndex: 0, pagesCount: pageSize, hasFooter: true, hasNextPage: hasNextPage, hasPreviousPage: hasPreviousPage, onGoToNext: handleGotoNext, onGoToPrevious: handleGoToPrevious, TableContainerProps: {
                    sx: {
                        height: 237.5,
                    },
                }, withoutDataMessage: t("No Activities"), emptyIconHeight: 100 })] }));
};
