/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DepartmentDeleteMutation = { __typename?: 'Mutation', vendorDepartmentDelete?: { __typename?: 'VendorDepartmentDelete', department?: { __typename?: 'Department', id: string } | null } | null };


export const DepartmentDeleteDocument = gql`
    mutation DepartmentDelete($id: ID!) {
  vendorDepartmentDelete(id: $id) {
    department {
      id
    }
  }
}
    `;
export type DepartmentDeleteMutationFn = Apollo.MutationFunction<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>;

/**
 * __useDepartmentDeleteMutation__
 *
 * To run a mutation, you first call `useDepartmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentDeleteMutation, { data, loading, error }] = useDepartmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>(DepartmentDeleteDocument, options);
      }
export type DepartmentDeleteMutationHookResult = ReturnType<typeof useDepartmentDeleteMutation>;
export type DepartmentDeleteMutationResult = Apollo.MutationResult<DepartmentDeleteMutation>;
export type DepartmentDeleteMutationOptions = Apollo.BaseMutationOptions<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>;