import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { isNurseUser } from "@/utils";
import { UserWorkspaceNurse } from "../UserWorkspaceNurse/UserWorkspaceNurse";
import { UserWorkspaceDoctor } from "../UserWorkspaceDoctor/UserWorkspaceDoctor";

export const UserWorkspace = () => {
  const { userInfo } = useOidcUserProfile();

  if (isNurseUser(userInfo)) {
    return <UserWorkspaceNurse />;
  }

  return <UserWorkspaceDoctor />;
};
