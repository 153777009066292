import { DiagnosisPage } from "pages/e-visit/Diagnosis/DiagnosisPage";
import { LabTestsPage } from "pages/e-visit/LabTests/LabTestsPage";
import { VisitNotePage } from "pages/e-visit/Notes/VisitNotePage";
import { PrescriptionPage } from "pages/e-visit/Prescription/PrescriptionPage";
import RadiologyPage from "pages/e-visit/Radiology/RadiologyPage";
import { Referrals } from "pages/e-visit/Referrals/Referral";
import { VitalSigns } from "pages/e-visit/VitalSigns/containers/VitalSigns/VitalSigns";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { LiveSignsPage, PatientProfileEmbed } from "shared/modules/patient";
import { LoggedInRoutes } from "./LoggedInRoutes";
import { visitCallGuestRoute } from "@/pages/VisitCallGuest";
/*
  TODO: check the RouteObject
  https://stackblitz.com/github/remix-run/react-router/tree/main/examples/route-objects?file=src/App.tsx
*/
export const RootRouter: FC = () => {
  return (
    <Routes>
      <Route path='/embed/e-visit/vital-signs' element={<VitalSigns />} />
      <Route path='/embed/e-visit/notes' element={<VisitNotePage />} />
      <Route path='/embed/e-visit/diagnosis' element={<DiagnosisPage />} />
      <Route path='/embed/e-visit/radiology' element={<RadiologyPage />} />
      <Route path='/embed/e-visit/lab-tests' element={<LabTestsPage />} />
      <Route path='/embed/e-visit/prescription' element={<PrescriptionPage />} />
      <Route path='/embed/e-visit/referrals' element={<Referrals />} />
      {/* How to use [Provider app URL]/patient-profile?token={my-token}&patientId={patient id} */}
      {/* How to use [Provider app URL]/patient-profile?token={my-token}&visitId={visit id} */}
      <Route path='/embed/patient-profile' element={<PatientProfileEmbed />} />
      <Route path='/embed/live-signs' element={<LiveSignsPage isDoctorConsole={false} />} />
      <Route key={visitCallGuestRoute.id} path={visitCallGuestRoute.route} element={visitCallGuestRoute.element} />

      <Route path='/*' element={<LoggedInRoutes />} />
      <Route path='app' element={<LoggedInRoutes />} />

      <Route path='404' element={<PageNotFound />} />
      <Route path='soon' element={<PageUnderConstruction />} />
    </Routes>
  );
};

const PageNotFound = () => (
  <div>
    <h2>404 PAGE Not Found!</h2>
  </div>
);
const PageUnderConstruction = () => (
  <div>
    <h2>PAGE UNDER CONSTRUCTION!</h2>
  </div>
);
