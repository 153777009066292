import { AppointmentFilterInput, AppointmentSortingField, AppointmentStatus, H_OrderDirection } from "@health/queries/types";
import moment from "moment";
import { FC, useMemo } from "react";
import { GridProvider } from "shared/components";
import { usePendingAppointmentsGridColumns } from "./usePendingAppointmentsGridColumns";
import { useFetchDashboardAppointmentsQuery } from "pages/dashboard/gql/queries";
import { DashboardGridProps } from "../../types/DashboardGrid";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isNurseUser, isVendorAdmin } from "utils";
import { useReactiveVar } from "@apollo/client";
import { userWorkspaceNurseVar } from "@/pages/UserWorkspace/vars";
import { PendingAppointmentsCustomizedGrid } from "./PendingAppointmentsCustomizedGrid";

export const PendingAppointmentsGrid: FC<DashboardGridProps> = props => {
  const { pageSize = 3, ...rest } = props;
  const currentNurseWorkDetails = useReactiveVar(userWorkspaceNurseVar);
  const doctorsFilterVarValue = currentNurseWorkDetails?.doctors?.map(e => e?.id);
  const { userInfo } = useOidcUserProfile();
  const isNurse = isNurseUser(userInfo);
  const isAdmin = isVendorAdmin(userInfo);
  const currentDate = useMemo(() => {
    return moment().format();
  }, []);
  const filterInput: Partial<AppointmentFilterInput> = {
    status: [AppointmentStatus.RescheduledByConsumer, AppointmentStatus.Request],
    requestedStartTime: {
      gte: currentDate,
    },
    doctors: isNurse && doctorsFilterVarValue ? doctorsFilterVarValue : [],
  };
  return (
    <GridProvider
      gridName='pendingAppointmentsList'
      columns={usePendingAppointmentsGridColumns()}
      query={useFetchDashboardAppointmentsQuery}
      filterInput={filterInput}
      pageSize={pageSize}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: AppointmentSortingField.LastModifiedDate,
        },
        isAdmin: isAdmin,
      }}
    >
      <PendingAppointmentsCustomizedGrid filterInput={filterInput} pageSize={pageSize} {...rest} />
    </GridProvider>
  );
};
