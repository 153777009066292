import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { isVendorAdmin } from "@/utils";
import { OptimaPriorRequestListContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { OptimaPriorRequestsRoutesBreadcrumb } from "../../constants/optima-prior-requests-routes-breadcrumbs";

export const ProviderOptimaPriorRequestsListContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { userInfo } = useOidcUserProfile();
  const { t } = useTranslation("provider");
  useEffect(() => {
    setBreadCrumb({
      title: OptimaPriorRequestsRoutesBreadcrumb(t),
    });
  }, []);

  const isProviderAdmin = isVendorAdmin(userInfo);
  return <OptimaPriorRequestListContainer isProviderAdmin={isProviderAdmin} />;
};
