import { DepartmentMedicalServiceCountableEdge } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useDepartmentMedicalServicesColumns = (): CustomTableColumnProps<DepartmentMedicalServiceCountableEdge>[] => {
  const { t } = useTranslation("provider");

  return useMemo(
    () => [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ node }) => node?.code,
      },
    ],
    [t]
  );
};
