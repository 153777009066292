import { makeStyles } from "@toolkit/ui";

export const useUserWorkspaceNurseStyle = makeStyles()(theme => ({
  message: {
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    cursor: "pointer",
    marginInline: 8,
  },
}));
