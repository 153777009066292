import { Grid } from "@toolkit/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { BranchesAutocomplete, DepartmentsAutocomplete, DoctorsAutocomplete } from "@health/autocompletes";
import { useReactiveVar } from "@apollo/client";
import { userWorkspaceNurseVar } from "../../vars";
import { convertUserWorkspaceNurseToFormValues } from "../../others";
import {
  userWorkspaceNurseUpsertFormSchema,
  userWorkspaceNurseUpsertFormDefaultValues,
  IUserWorkspaceNurseUpsertFormValues,
} from "./UserWorkspaceNurseUpsertFormSchema";

export type IUserWorkspaceNurseUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IUserWorkspaceNurseUpsertFormValues;
  };
};

type UserWorkspaceNurseUpsertFormProps = {
  onChange: (event: IUserWorkspaceNurseUpsertFormEvent) => void;
};

export type UserWorkspaceNurseUpsertFormRef = {
  getForm: () => UseFormReturn<IUserWorkspaceNurseUpsertFormValues>;
  submit: () => void;
};

const UserWorkspaceNurseUpsertFormForwardRef: ForwardRefRenderFunction<
  UserWorkspaceNurseUpsertFormRef,
  UserWorkspaceNurseUpsertFormProps
> = (props, ref) => {
  const { onChange } = props;

  const nurseCurrentWorkspace = useReactiveVar(userWorkspaceNurseVar);

  const form = useCustomForm<IUserWorkspaceNurseUpsertFormValues>({
    defaultValues: userWorkspaceNurseUpsertFormDefaultValues,
    schema: userWorkspaceNurseUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const selectedBranchId = watch("branch")?.value?.id;
  const selectedDepartmentId = watch("department")?.value?.id;

  const onSubmit = (values: IUserWorkspaceNurseUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (nurseCurrentWorkspace) {
      const _nurseCurrentWorkspace = convertUserWorkspaceNurseToFormValues(nurseCurrentWorkspace);
      setValues(_nurseCurrentWorkspace);
    }
  }, [nurseCurrentWorkspace, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BranchesAutocomplete name={"branch"} filter={{ isActive: true }} />
        </Grid>

        <Grid item xs={12}>
          <DepartmentsAutocomplete name={"department"} disabled={!selectedBranchId} filter={{ branch: selectedBranchId }} />
        </Grid>

        <Grid item xs={12}>
          <DoctorsAutocomplete
            name={"doctors"}
            multiple
            disabled={!selectedDepartmentId}
            filter={{ departmentIds: [selectedDepartmentId], isActive: true }}
          />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const UserWorkspaceNurseUpsertForm = forwardRef(UserWorkspaceNurseUpsertFormForwardRef);
