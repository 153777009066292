/* eslint-disable max-lines */
import { GuidedCareJourneyIntervention, GuidedCareJourneyStatus, HealthHistoryType } from "@health/queries/types";
import { formatUserNameFull, getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, AvatarGroup, Box, CustomIcon, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@toolkit/ui";
import moment from "moment/moment";
import { useProfileContext } from "../../context/ProfileContext";
import React from "react";
import { HealthConditions } from "./Conditions";
import { JourneyStatistics } from "./JourneyStatistics";
import { MaskProfilePhoto } from "./MaskProfilePhoto";
import { PatientInformationSection } from "./PatientInformationSection";
import { PatientGuidedCarePrograms } from "./PatientGuidedCarePrograms/PatientGuidedCarePrograms";
import { useGuidedCareJourneyTemplateInterventionListQuery } from "../../gql";
import { GuidedCareJourneyEligibleInterventionsAlert } from "../GuidedCareJourneyInterventions/GuidedCareJourneyEligibleInterventionsAlert/GuidedCareJourneyEligibleInterventionsAlert";
import { GuidedCareJourneyGoals } from "../GuidedCareJourneyInterventions/GuidedCareJourneyGoals/GuidedCareJourneyGoals";
import { HealthDocuments } from "./HealthDocuments";

const msg = "N/A";

export const PatientInformation = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width:1340px)");

  const {
    patient,
    userId,
    patientHealthHistory,
    selectedHealthProgram,
    handleProgramSelected,
    onClose: handleToggleDrawer,
    hasGuidedCareHealthProgramTeam,
    guidedCareHealthProgramTeamPageInfo,
    guidedCareHealthPrograms,
    guidedCareProgramTeamLeaderPhoto,
    guidedCareProgramTeamMembersWithoutTeamLeader,
    guidedCareHealthProgramTeamFetchMore: handleGuidedCareHealthProgramTeamFetchMore,
  } = useProfileContext();

  const { data, loading } = useGuidedCareJourneyTemplateInterventionListQuery({
    variables: {
      first: 1,
      filter: {
        healthProgramId: selectedHealthProgram,
        patientsIds: [userId!],
        statuses: [GuidedCareJourneyStatus.InProgress],
      },
    },
    skip: !selectedHealthProgram || !userId,
  });

  const guidedCareJourney = data?.guidedCareJourneys?.edges?.[0]?.node;

  const eligibleInterventions = (guidedCareJourney?.eligibleInterventions || []) as GuidedCareJourneyIntervention[];

  const insuranceCards = patient?.activeHealthProgramMembers?.[0];

  const allergies = patientHealthHistory
    ?.filter(item => item?.healthHistoryType! === HealthHistoryType.Allergy)
    .map(item => item?.display || item?.code);

  const getAge = () => {
    const birthdate = moment(patient?.dateOfBirth);
    const currentDate = moment();
    const years = currentDate.diff(birthdate, "years");
    birthdate.add(years, "years");
    const months = currentDate.diff(birthdate, "months");
    let age = `${years} ${t("Years")}`;
    if (months > 0) {
      age = `${age} ${months} ${t("Months")}`;
    }
    return age;
  };

  const patientInfo = [t(patient?.gender!), patient?.dateOfBirth && getAge(), t(patient?.user?.preferredLanguage?.display!)].filter(
    Boolean
  );

  return (
    <Grid container padding='20px 0px' spacing={2}>
      {!loading && eligibleInterventions?.length > 0 && (
        <Grid item xs={12}>
          <GuidedCareJourneyEligibleInterventionsAlert
            eligibleInterventions={eligibleInterventions}
            guidedCareJourneyId={guidedCareJourney?.id!}
            patientName={`${patient?.firstName} ${patient?.lastName}`}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container padding='20px 0px' flexWrap={"nowrap"}>
          <Grid item>
            <IconButton
              onClick={handleToggleDrawer}
              sx={{
                width: 30,
                height: 30,
                color: theme.palette.primary.main,
                alignSelf: "start",
              }}
            >
              <CustomIcon icon='close' viewBox='-1 0 26 25' />
            </IconButton>
          </Grid>

          <Grid item>
            <Box sx={{ width: "90px", height: "90px" }}>
              <MaskProfilePhoto src={patient?.user?.photo!} />
            </Box>
          </Grid>

          <Grid container spacing={1} item flexWrap={isMobile ? "wrap" : "nowrap"}>
            <Grid item>
              <PatientInformationSection title={patient?.firstName || patient?.lastName ? formatUserNameFull(patient) : msg}>
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  {patientInfo?.map((item, index) => (
                    <Box display='flex' alignItems='center' key={item}>
                      {index > 0 && (
                        <Box sx={{ borderRadius: 25, marginInline: 1, width: 7, height: 7, background: theme.palette.primary[200] }} />
                      )}

                      <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{item}</Typography>
                    </Box>
                  ))}
                </Box>

                <Typography mt={1} fontSize={theme.mixins.fonts.fontSize.sm}>
                  {insuranceCards?.payer?.name || ""}
                </Typography>
              </PatientInformationSection>
            </Grid>

            <Grid item xs container spacing={1} justifyContent='center' flexWrap={isMobile ? "wrap" : "nowrap"}>
              <Grid item xs='auto'>
                <HealthConditions />
              </Grid>

              <Grid item xs='auto'>
                <PatientInformationSection title={t("Allergies")} hasItems items={allergies} />
              </Grid>
              <Grid item xs='auto'>
                <HealthDocuments />
              </Grid>
            </Grid>

            <Grid item>
              <PatientGuidedCarePrograms
                hasMore={guidedCareHealthProgramTeamPageInfo?.hasNextPage!}
                programs={guidedCareHealthPrograms!}
                selected={selectedHealthProgram}
                onFetchMore={handleGuidedCareHealthProgramTeamFetchMore}
                onChange={handleProgramSelected!}
              />

              <PatientInformationSection title={t("Care Team")}>
                {hasGuidedCareHealthProgramTeam ? (
                  <AvatarGroup
                    max={5}
                    sx={{
                      justifyContent: "flex-end",
                    }}
                  >
                    {guidedCareProgramTeamLeaderPhoto && (
                      <Avatar
                        sx={{
                          border: `1.5px solid ${theme.palette.secondary.main} !important`,
                          backgroundColor: theme.palette.common.white,
                          zIndex: 1,
                        }}
                        alt='Leader'
                        src={getMediaLink(guidedCareProgramTeamLeaderPhoto!)}
                      />
                    )}
                    {guidedCareProgramTeamMembersWithoutTeamLeader?.map(item => {
                      return <Avatar key={item?.id} alt={item?.id} src={getMediaLink(item?.photo!)} />;
                    })}
                  </AvatarGroup>
                ) : (
                  <Box
                    sx={{
                      width: "fit-content",
                      border: `1px solid ${theme.palette.primary[100]}`,
                      borderRadius: "5px",
                      padding: "5px 10px",
                      background: theme.palette.background.default,
                    }}
                  >
                    <Typography fontSize={theme.mixins.fonts.fontSize.xs} flexWrap={"wrap"}>
                      {msg}
                    </Typography>
                  </Box>
                )}
              </PatientInformationSection>
            </Grid>

            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GuidedCareJourneyGoals />
                </Grid>

                <Grid item xs={12}>
                  <JourneyStatistics
                    healthProgramId={selectedHealthProgram!}
                    activeInterventionsValue={guidedCareJourney?.activeInterventions?.length}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
