import { jsx as _jsx } from "react/jsx-runtime";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { GridProvider } from "../../../../shared/components";
import { useOptimaClaimActivitiesQuery } from "../../../OptimaClaims/gql";
import { useOnOptimaHaveNewNotification } from "@health/sse";
import { useOptimaClaimActivitiesColumns } from "./useOptimaClaimActivitiesColumns";
export const OptimaClaimsActivitiesGridContainer = props => {
    const { filters } = props;
    const { refetch } = useGridContext();
    useOnOptimaHaveNewNotification(() => {
        refetch({ first: 10 });
    });
    return (_jsx(GridProvider, { gridName: "OptimaClaimsActivitiesList", query: useOptimaClaimActivitiesQuery, columns: useOptimaClaimActivitiesColumns(), hasTableSetting: true, filterInput: filters, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsx(TableSettingComponent, {}), children: _jsx(TableGrid, {}) }) }));
};
