import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useVisitCallHook } from "./useVisitCallHook";
import { Call } from "@health/meetora";
import { visitCallGuestBaseRoute } from "@/pages/VisitCallGuest/route";

type VisitCallProps = {
  meetingLink: string;
  visitId: string;
};

export const VisitCall: FC<VisitCallProps> = ({ meetingLink: invitationCode, visitId }) => {
  const { renderDoctorConsole } = useVisitCallHook({ visitId });
  const invitationLink = `${location.origin}/${visitCallGuestBaseRoute}`;
  const navigate = useNavigate();
  const onGoBack = useCallback(() => navigate("/"), [navigate]);
  const onRequestClose = useCallback(() => {
    window.close();
    navigate("/");
  }, [navigate]);

  return (
    <Call
      invitationLink={invitationLink}
      invitationCode={invitationCode}
      onClose={onRequestClose}
      onGoBack={onGoBack}
      renderDoctorConsole={renderDoctorConsole}
    />
  );
};
