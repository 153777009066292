import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CustomTable, Typography } from "@toolkit/ui";
import { useRemittanceActivitiesList } from "./useRemittanceActivitiesList.hook";
import { useRemittanceDrawerStyles } from "../../../OptimaClaims/Components/RemittanceDrawer/RemittanceDrawerStyles";
export const RemittanceActivitiesList = props => {
    const { id } = props;
    const { t, tableData, hasNextPage, hasPreviousPage, isLoading, handleGotoNext, handleGoToPrevious, pageSize, columns, totalCount } = useRemittanceActivitiesList(id);
    const { classes } = useRemittanceDrawerStyles();
    return (_jsxs(Box, { children: [_jsx(Typography, { mb: 2, className: classes.sectionTitle, children: t("Remittance Activities: ({{totalCount}})", { totalCount }) }), _jsx(CustomTable, { isLoading: isLoading, data: tableData, columns: columns, pageSize: pageSize, pageIndex: 0, pagesCount: pageSize, hasFooter: true, hasNextPage: hasNextPage, hasPreviousPage: hasPreviousPage, onGoToNext: handleGotoNext, onGoToPrevious: handleGoToPrevious, TableContainerProps: {
                    sx: {
                        height: 237.5,
                    },
                }, withoutDataMessage: t("No Activities"), emptyIconHeight: 100 })] }));
};
