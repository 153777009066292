import { Box, CircularProgress, CustomIcon, IconButton, Typography, useTheme } from "@toolkit/ui";
import { useProfileContext } from "@/shared/modules/patient/context/ProfileContext";
import { useTranslation } from "@toolkit/i18n";

export const ConsentPendingView = () => {
  const { t } = useTranslation("provider");
  const theme = useTheme();
  const { patientName, onClose: handleClose } = useProfileContext();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 1,
        }}
      >
        <IconButton onClick={handleClose}>
          <CustomIcon icon='xAlert' />
        </IconButton>
      </Box>
      <Box
        p={1}
        sx={{
          height: 150,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <CircularProgress color={"success"} size={60} />
        <Typography margin={1} fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {t("Waiting Access From")} {patientName || t("Patient")}
        </Typography>
      </Box>
    </>
  );
};
