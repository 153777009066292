import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const departmentUpsertFormSchema = z.object({
  name: z.string().min(3).max(256),
  code: z.string().min(3).max(256),
  directions: z.string().nullish(),
  branch: zodSchema.branch,
  medicalServices: z.array(z.string()),
  description: z.string().nullish(),
});

export type IDepartmentUpsertFormValues = z.infer<typeof departmentUpsertFormSchema>;

export const departmentUpsertFormDefaultValues: Partial<IDepartmentUpsertFormValues> = {
  name: undefined,
  code: undefined,
  directions: undefined,
  branch: undefined,
  medicalServices: [],
  description: undefined,
};
