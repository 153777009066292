import { VisitDoctorNoteInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getApolloContextFormToken } from "shared/utils";
import {
  VisitDoctorNoteUpdateDocument,
  VisitDoctorNoteUpdateMutation,
  useGetVisitToPatientNoteQuery,
  useVisitNotToPatientUpdateMutation,
} from "../../../gql";

export const useToPatientNote = ({ visitId, token }: { visitId?: string; token?: string }) => {
  const { t } = useTranslation("provider");
  const { failed, succeeded } = useAddToast();

  const methods = useForm<VisitDoctorNoteInput>({
    mode: "onChange",
  });
  const {
    data,
    loading: isDataLoading,
    refetch,
  } = useGetVisitToPatientNoteQuery({
    variables: {
      visitId,
    },
    context: getApolloContextFormToken(token),
    skip: !visitId,
  });

  const [visitDoctorNoteUpdateMutation, { loading: isUpdateSubmitting }] = useVisitNotToPatientUpdateMutation({
    context: getApolloContextFormToken(token),
    refetchQueries: [VisitDoctorNoteUpdateDocument],
    notifyOnNetworkStatusChange: true,
    onCompleted: (response: VisitDoctorNoteUpdateMutation) => {
      const updateErrors = response?.visitDoctorNoteUpdate?.visitErrors;
      if (updateErrors && updateErrors?.length > 0) {
        methods.setError("doctorNote", {
          message: formatMessageErrors(updateErrors),
          type: "value",
        });
      } else {
        refetch();
        methods.setValue("doctorNote", response?.visitDoctorNoteUpdate?.visit?.doctorNote);
        succeeded(t("Visit Patient note updated successfully"));
      }
    },
    onError: () => {
      failed(t("Failed to update visit patient note"));
    },
  });

  const handleSubmit = (InputData: VisitDoctorNoteInput) => {
    visitDoctorNoteUpdateMutation({
      variables: {
        input: {
          doctorNote: InputData?.doctorNote,
          visit: String(visitId),
        },
      },
    });
  };
  const isSubmitting = isUpdateSubmitting;
  const defaultData = data?.visit?.noteToPatient;
  useEffect(() => {
    if (defaultData) {
      methods.setValue("doctorNote", defaultData);
    }
  }, [defaultData]);

  return {
    errors: methods.formState.errors,
    methods,
    isSubmitting,
    defaultData,
    isDataLoading,
    handleSubmit,
  };
};
