import React, { FC } from "react";
import { IPatientHealthParameterCardEvent, PatientHealthParameterCard } from "../PatientHealthParameterCard/PatientHealthParameterCard";
import { usePatientHealthParameters } from "../../hooks";
import { pickLocalizedValue } from "@toolkit/i18n";
import { convertPatientHealthParametersNumberToChartData } from "../../others";
import { PatientHealthParameterChart } from "../PatientHealthParameterChart/PatientHealthParameterChart";
import { Grid } from "@toolkit/ui";
import { PatientHealthParameterHistoryModalApi } from "../../modals";

type PatientHealthParameterNumberProps = {
  visitId?: string;
  patientId?: string;
  code: string;
  token: string;
};

export const PatientHealthParameterNumber: FC<PatientHealthParameterNumberProps> = props => {
  const { visitId, patientId, code, token } = props;

  const { data, loading } = usePatientHealthParameters({
    first: 10,
    visitId,
    patientId,
    token,
    codes: [code],
  });

  const patientHealthParameter = data?.[0];
  const healthParameter = patientHealthParameter?.healthParameter;
  const unit = pickLocalizedValue(healthParameter?.unit?.display, healthParameter?.unit?.arabicDisplay);

  const formattedData = convertPatientHealthParametersNumberToChartData(data);
  const chartData = formattedData?.length ? [{ data: formattedData }] : [];

  const onPatientHealthParameterCardChange = (event: IPatientHealthParameterCardEvent) => {
    if (event.type === "EXPAND_CLICK") {
      PatientHealthParameterHistoryModalApi.open({
        type: "NUMBER",
        title: healthParameter?.display!,
        patientId,
        token,
        codes: [code],
      });
    }
  };

  return (
    <PatientHealthParameterCard
      code={code}
      token={token}
      patientHealthParameter={patientHealthParameter}
      isLoading={loading}
      isExpandIconShown={chartData?.length ? !visitId : false}
      onChange={onPatientHealthParameterCardChange}
    >
      {!!chartData?.length && (
        <Grid container>
          <Grid item xs={12}>
            <PatientHealthParameterChart chartType={healthParameter?.chartType!} chartData={chartData} unit={unit!} isLoading={loading} />
          </Grid>
        </Grid>
      )}
    </PatientHealthParameterCard>
  );
};
