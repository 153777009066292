/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorGetQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type DoctorGetQuery = { __typename?: 'Query', doctor?: { __typename?: 'Doctor', id: string, seniority?: Types.DoctorSeniorityEnum | null, bio?: string | null, user?: { __typename?: 'User', id: string, fullName?: string | null, photo?: string | null } | null } | null };


export const DoctorGetDocument = gql`
    query DoctorGet($id: ID) {
  doctor(id: $id) {
    id
    seniority
    bio
    user {
      id
      fullName
      photo
    }
  }
}
    `;

/**
 * __useDoctorGetQuery__
 *
 * To run a query within a React component, call `useDoctorGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDoctorGetQuery(baseOptions?: Apollo.QueryHookOptions<DoctorGetQuery, DoctorGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorGetQuery, DoctorGetQueryVariables>(DoctorGetDocument, options);
      }
export function useDoctorGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorGetQuery, DoctorGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorGetQuery, DoctorGetQueryVariables>(DoctorGetDocument, options);
        }
export type DoctorGetQueryHookResult = ReturnType<typeof useDoctorGetQuery>;
export type DoctorGetLazyQueryHookResult = ReturnType<typeof useDoctorGetLazyQuery>;
export type DoctorGetQueryResult = Apollo.QueryResult<DoctorGetQuery, DoctorGetQueryVariables>;