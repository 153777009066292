import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "@/shared/components";
import { OrderDirection, VendorDepartmentOrderField } from "@health/queries/types";
import { useDepartmentListQuery, useDepartmentDeleteMutation } from "../../gql";
import { useSetDepartmentsBreadcrumbs } from "../../hooks";
import { departmentsPaths } from "../../constants";
import { IDepartmentListNode } from "../../types";
import { useDepartmentListContainerColumns } from "./useDepartmentListContainerColumns";

export const DepartmentListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(departmentsPaths.create.fullPath);
  };

  const onEditRowClick = (department: IDepartmentListNode) => {
    navigate(departmentsPaths.update.fullPathWithParams({ departmentId: department?.id }));
  };

  useSetDepartmentsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"departmentList"}
      query={useDepartmentListQuery}
      columns={useDepartmentListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: true,
        onEditRow: onEditRowClick,
      }}
      deleteItemProps={{
        name: "Department",
        entityTypeName: "Department",
        useDeleteMutation: useDepartmentDeleteMutation,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Asc,
          field: VendorDepartmentOrderField.Name,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
