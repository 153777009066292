import { useOpenState } from "@toolkit/core";
import { AuditHistoryDrawer, Box } from "@toolkit/ui";
import { FC } from "react";
import { OrderEventsTimeline, OrdersEventsType } from "./OrderEventsTimeline";

export const OrderEventsDrawer: FC<{ events: OrdersEventsType }> = ({ events }) => {
  const { handleClose, handleOpen, open } = useOpenState();

  return (
    <AuditHistoryDrawer open={open} onClickOpen={handleOpen} onClickClose={handleClose}>
      <Box padding='14px' height='100vh' overflow='auto' id='scrollableDiv'>
        <OrderEventsTimeline events={events} />
      </Box>
    </AuditHistoryDrawer>
  );
};
