/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.VendorDepartmentUpdateInput;
}>;


export type DepartmentUpdateMutation = { __typename?: 'Mutation', vendorDepartmentUpdate?: { __typename?: 'VendorDepartmentUpdate', department?: { __typename?: 'Department', id: string } | null } | null };


export const DepartmentUpdateDocument = gql`
    mutation DepartmentUpdate($id: ID!, $input: VendorDepartmentUpdateInput!) {
  vendorDepartmentUpdate(id: $id, input: $input) {
    department {
      id
    }
  }
}
    `;
export type DepartmentUpdateMutationFn = Apollo.MutationFunction<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>;

/**
 * __useDepartmentUpdateMutation__
 *
 * To run a mutation, you first call `useDepartmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentUpdateMutation, { data, loading, error }] = useDepartmentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>(DepartmentUpdateDocument, options);
      }
export type DepartmentUpdateMutationHookResult = ReturnType<typeof useDepartmentUpdateMutation>;
export type DepartmentUpdateMutationResult = Apollo.MutationResult<DepartmentUpdateMutation>;
export type DepartmentUpdateMutationOptions = Apollo.BaseMutationOptions<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>;