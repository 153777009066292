import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CustomTable, Typography } from "@toolkit/ui";
import { useOptimaClaimActivitiesList } from "./useOptimaClaimActivitiesList.hook";
import { usePriorAuthorizationDrawerStyles } from "../../../OptimaPriorRequests/components/PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
import { useTranslation } from "@toolkit/i18n";
export const OptimaClaimActivitiesList = props => {
    const { id } = props;
    const { tableData, hasNextPage, hasPreviousPage, isLoading, handleGotoNext, handleGoToPrevious, pageSize, columns, totalCount } = useOptimaClaimActivitiesList(id);
    const { t } = useTranslation("domains");
    const { classes } = usePriorAuthorizationDrawerStyles();
    return (_jsxs(Box, { children: [_jsx(Box, { className: classes.claimsActivityContainer, children: _jsx(Typography, { className: classes.sectionTitle, mt: 4, mb: 2, children: `Activities: (${totalCount})` }) }), _jsx(CustomTable, { isLoading: isLoading, data: tableData, columns: columns, pageSize: pageSize, pageIndex: 0, pagesCount: pageSize, hasFooter: true, hasNextPage: hasNextPage, hasPreviousPage: hasPreviousPage, onGoToNext: handleGotoNext, onGoToPrevious: handleGoToPrevious, TableContainerProps: {
                    sx: {
                        height: 237.5,
                    },
                }, withoutDataMessage: t("No Activities"), emptyIconHeight: 100 })] }));
};
