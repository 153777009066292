import { CustomDialog, ShowButton, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";

type DoctorBioProps = {
  bio?: string | null;
};

export const DoctorBio: FC<DoctorBioProps> = props => {
  const { bio } = props;

  const { t } = useTranslation("provider");

  return (
    <CustomDialog type={"info"} title={t("Doctor's Bio")} button={<ShowButton disabled={!bio} />}>
      <Typography>{bio}</Typography>
    </CustomDialog>
  );
};
