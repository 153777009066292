import { FC, MouseEvent, PropsWithChildren, useCallback } from "react";
import { CallDuration, useActiveCall } from "@health/meetora";
import {
  Box,
  Button,
  Container,
  Grid,
  MuiCallEndIcon,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  MuiVolumeOffIcon,
  MuiVolumeUpIcon,
  Typography,
  useTheme,
  VideoConference,
} from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { PeerTitle } from "@health/meetora";
import { useNavigate } from "react-router-dom";

export const VisitCallStatusBar: FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("provider");
  const { call, callInfo } = useActiveCall()!;
  const {
    muteAudio,
    muteVideo,
    muteSpeaker,
    dropCall,
    state: { audioMuted, videoMuted, speakerMuted },
  } = call;
  const handleToggleAudio = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteAudio?.(m => !m);
    },
    [muteAudio]
  );
  const handleToggleVideo = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteVideo?.(m => !m);
    },
    [muteVideo]
  );
  const handleToggleSpeaker = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      muteSpeaker?.(m => !m);
    },
    [muteSpeaker]
  );
  const handleDropCall = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dropCall?.();
    },
    [dropCall]
  );

  const handleBackToCall = useCallback(() => {
    if (!callInfo?.callUrl) {
      return;
    }

    navigate(callInfo.callUrl.replace(location.origin, ""));
  }, [callInfo?.callUrl, navigate]);

  if (!call) {
    return null;
  }

  return (
    <Box display={"flex"} bgcolor={theme.palette.common.white} width='100vw' boxShadow={theme.mixins.shadows.md}>
      <Container fixed>
        <Grid
          container
          spacing={2}
          justifyContent='center'
          alignItems='center'
          height='100px'
          sx={{ cursor: "pointer" }}
          onClick={handleBackToCall}
        >
          <Grid item xs='auto'>
            <Box display='flex' justifyContent='center' alignItems='center' width='90px' height='85px' bgcolor={theme.palette.success.main}>
              <VideoConference
                sx={{
                  width: "40px",
                  height: "40px",
                  "& path": {
                    fill: `${theme.palette.common.white} !important`,
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
              <Typography fontWeight='bold'>
                <PeerTitle peer={call.conferencePeer} />
              </Typography>
              <Typography>
                <CallDuration call={call} />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs='auto'>
            <Button title={!videoMuted ? t("Stop video") : t("Start video")} variant='text' onClick={handleToggleVideo}>
              {!videoMuted ? <MuiVideocamIcon /> : <MuiVideocamOffIcon />}
            </Button>

            <Button title={!audioMuted ? t("Mute") : t("Unmute")} variant='text' onClick={handleToggleAudio}>
              {!audioMuted ? <MuiMicIcon /> : <MuiMicOffIcon />}
            </Button>
            <Button variant='text' onClick={handleToggleSpeaker}>
              {!speakerMuted ? <MuiVolumeUpIcon /> : <MuiVolumeOffIcon />}
            </Button>
            <Button title={t("End")} onClick={handleDropCall} color='error'>
              <MuiCallEndIcon />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
