/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVisitToPatientNoteQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetVisitToPatientNoteQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', noteToPatient?: string | null } | null };


export const GetVisitToPatientNoteDocument = gql`
    query getVisitToPatientNote($visitId: ID) {
  visit(id: $visitId) {
    noteToPatient
  }
}
    `;

/**
 * __useGetVisitToPatientNoteQuery__
 *
 * To run a query within a React component, call `useGetVisitToPatientNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitToPatientNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitToPatientNoteQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useGetVisitToPatientNoteQuery(baseOptions?: Apollo.QueryHookOptions<GetVisitToPatientNoteQuery, GetVisitToPatientNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitToPatientNoteQuery, GetVisitToPatientNoteQueryVariables>(GetVisitToPatientNoteDocument, options);
      }
export function useGetVisitToPatientNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitToPatientNoteQuery, GetVisitToPatientNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitToPatientNoteQuery, GetVisitToPatientNoteQueryVariables>(GetVisitToPatientNoteDocument, options);
        }
export type GetVisitToPatientNoteQueryHookResult = ReturnType<typeof useGetVisitToPatientNoteQuery>;
export type GetVisitToPatientNoteLazyQueryHookResult = ReturnType<typeof useGetVisitToPatientNoteLazyQuery>;
export type GetVisitToPatientNoteQueryResult = Apollo.QueryResult<GetVisitToPatientNoteQuery, GetVisitToPatientNoteQueryVariables>;