import { CredentialDialog } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, CustomToggleButton } from "@toolkit/ui";
import { GetBranchUsersQuery } from "pages/HealthProviderManagement/Branches/gql";
import { useMemo } from "react";

export type BranchUsersColumnTypes = CustomTableColumnProps<GetBranchUsersQuery>;
export type BranchUsersColumnsTypes = BranchUsersColumnTypes[];

export const useGetBranchesUsersColumns = () => {
  const { t } = useTranslation("provider");
  return useMemo(() => {
    return [
      {
        key: "FirstName",
        header: t("First Name"),
        accessor: "firstName",
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      {
        key: "LastName",
        header: t("Last Name"),
        accessor: "lastName",
      },
      {
        key: "Email",
        header: t("Email"),
        accessor: "email",
      },
      {
        key: "Mobile",
        header: t("Mobile"),
        accessor: "mobile",
      },
      {
        key: "credential",
        header: t("eRxHub Credential"),
        accessor: ({ id, isActive }) => (
          <CredentialDialog isDisabled={!isActive} targetId={id || ""} type='clinician' title={t("eRxHub Credential")} />
        ),
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => <CustomToggleButton checked={row?.isActive} />,
      },
    ];
  }, []);
};
