import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, MuiDrawer, ShowButton, Typography } from "@toolkit/ui";
import { useRemittanceDrawerStyles } from "./RemittanceDrawerStyles";
import { RemittanceDrawerDetails } from "./RemittanceDrawerDetails";
import { ClaimGeneralInformation } from "../ClaimGeneralInformation/ClaimGeneralInformation";
import { ClaimTransactionDetails } from "../ClaimTransactionDetails/ClaimTransactionDetails";
import { ClaimEncounterDetails } from "../ClaimEncounterDetails/ClaimEncounterDetails";
import { RemittanceActivitiesList } from "../../../OptimaClaims/containers";
export const RemittanceDrawer = props => {
    var _a;
    const { claim } = props;
    const { remittance } = claim;
    const { classes } = useRemittanceDrawerStyles();
    const { t } = useTranslation("domains");
    const { handleOpen, handleToggle, open } = useOpenState();
    return (remittance === null || remittance === void 0 ? void 0 : remittance.id) ? (_jsxs(_Fragment, { children: [_jsx(ShowButton, { onClick: handleOpen }), _jsx(MuiDrawer, { anchor: 'right', open: open, onClose: handleToggle, PaperProps: {
                    className: classes.drawer,
                }, children: _jsxs(Box, { padding: 2, children: [_jsx(Typography, { className: classes.sectionTitle, children: t("General Information") }), _jsx(ClaimGeneralInformation, { claim: claim }), _jsx(Typography, { className: classes.sectionTitle, children: t("Transaction Details") }), _jsx(ClaimTransactionDetails, { claim: claim }), _jsx(Typography, { className: classes.sectionTitle, children: t("Encounter Details") }), _jsx(ClaimEncounterDetails, { claim: claim }), _jsx(Typography, { className: classes.sectionTitle, children: t("Remittance Summary") }), _jsx(RemittanceDrawerDetails, { claim: claim }), _jsx(RemittanceActivitiesList, { id: (_a = claim === null || claim === void 0 ? void 0 : claim.remittance) === null || _a === void 0 ? void 0 : _a.id })] }) })] })) : (_jsx(Typography, { className: classes.pending, children: t("Pending") }));
};
