import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { FC, useRef } from "react";
import {
  UserWorkspaceNurseUpsertForm,
  UserWorkspaceNurseUpsertFormRef,
  IUserWorkspaceNurseUpsertFormEvent,
} from "../../forms/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertForm";

type UserWorkspaceNurseUpsertModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  hasClose: boolean;
  onClose: () => void;
  onChange: (values: IUserWorkspaceNurseUpsertFormEvent) => void;
};

export const UserWorkspaceNurseUpsertModal: FC<UserWorkspaceNurseUpsertModalProps> = props => {
  const { isOpen, isLoading, hasClose, onClose, onChange } = props;

  const { t } = useTranslation("provider");

  const userWorkspaceNurseUpsertFormRef = useRef<UserWorkspaceNurseUpsertFormRef>(null);

  const onAssignClick = () => {
    userWorkspaceNurseUpsertFormRef.current?.submit();
  };

  const handleClose = () => {
    if (!hasClose) return;

    onClose();
  };

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      DialogTitleProps={{
        title: t("Complete Your Current Working Data"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onAssignClick}
          >
            {t("Assign")}
          </Button>
        ),
      }}
    >
      <UserWorkspaceNurseUpsertForm ref={userWorkspaceNurseUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
