import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useMemo } from "react";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, format, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { BACKEND_DATE_FORMAT, BACKEND_DATE_TIME_FORMAT, toTitleCase } from "@toolkit/core";
import { PriorAuthorizationDrawer } from "../../../OptimaPriorRequests/components";
import { produce } from "immer";
import moment from "moment";
import { getAutocompleteEnumFilter, transactionStatusTypeOptionsMap } from "@health/enum-options";
import { transactionSourceTypeOptionsMap } from "@health/enum-options";
import { getOptimaAuthorizationStatusColor } from "../../../../utils";
import { GridCellText } from "../../../../components";
export const useOptimaPriorRequestListContainerColumns = (props) => {
    const { isProviderAdmin, selectedKpiMode } = props;
    const { t } = useTranslation("domains");
    const theme = useTheme();
    const getFilterBasedOnKpiMode = (filter) => {
        return selectedKpiMode === "all" ? filter : undefined;
    };
    return useMemo(() => {
        return [
            {
                key: "id",
                header: t("ID"),
                accessor: ({ id }) => _jsx(GridCellText, { text: id }),
                filter: getFilterBasedOnKpiMode({
                    type: "string",
                    name: "ids",
                }),
            },
            {
                key: "transactionId",
                header: t("Transaction Id"),
                accessor: ({ transactionId }) => _jsx(GridCellText, { text: transactionId }),
                filter: getFilterBasedOnKpiMode({
                    type: "string",
                    name: "transactionId",
                }),
            },
            {
                key: "transactionDate",
                header: t("Transaction Date"),
                accessor: ({ transactionDate }) => (_jsx(Box, { sx: { width: "max-content" }, children: transactionDate && format(transactionDate, BACKEND_DATE_FORMAT, i18n.language) })),
            },
            {
                key: "transactionType",
                header: t("Transaction Type"),
                accessor: ({ transactionType }) => transactionType || "-",
            },
            {
                key: "branch",
                header: t("Branch"),
                accessor: ({ Branch }) => pickLocalizedValue(Branch === null || Branch === void 0 ? void 0 : Branch.name, Branch === null || Branch === void 0 ? void 0 : Branch.nameAr) || "-",
                hideFromPreferencesAndTable: !isProviderAdmin,
            },
            {
                key: "authorizationStatus",
                header: t("Status"),
                accessor: ({ priorAuthorization }) => {
                    var _a;
                    return (_jsx(Typography, { fontWeight: theme.mixins.fonts.fontWeight.medium, color: getOptimaAuthorizationStatusColor((priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.status) || "", theme), children: ((_a = transactionStatusTypeOptionsMap[(priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.status) || ""]) === null || _a === void 0 ? void 0 : _a.label) || t("Pending") }));
                },
                filter: getFilterBasedOnKpiMode(getAutocompleteEnumFilter("TransactionStatusType", "authorizationStatus", { multiple: true })),
            },
            {
                key: "patientId",
                header: t("Patient Id"),
                accessor: ({ patientId }) => _jsx(GridCellText, { text: patientId }),
            },
            {
                key: "memberId",
                header: t("Member Id"),
                accessor: ({ memberId }) => _jsx(GridCellText, { text: memberId }),
                filter: getFilterBasedOnKpiMode({
                    type: "string",
                    name: "memberId",
                }),
            },
            {
                key: "emiratesIDNumber",
                header: t("Emirates ID"),
                accessor: ({ emiratesIDNumber }) => _jsx(GridCellText, { text: emiratesIDNumber }),
                filter: getFilterBasedOnKpiMode({
                    type: "string",
                    name: "emirateIdNumber",
                }),
            },
            {
                key: "payer",
                header: t("Payer"),
                accessor: ({ payer }) => _jsx(TruncateTypography, { text: toTitleCase((payer === null || payer === void 0 ? void 0 : payer.name) || "-") }),
            },
            {
                key: "payerId",
                header: t("Payer Id"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "string",
                    name: "payerId",
                },
            },
            {
                key: "receiver",
                header: t("Receiver"),
                accessor: ({ receiver }) => _jsx(TruncateTypography, { text: toTitleCase((receiver === null || receiver === void 0 ? void 0 : receiver.name) || "-") }),
            },
            {
                key: "receiverId",
                header: t("Receiver Id"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "string",
                    name: "receiverId",
                },
            },
            {
                key: "source",
                header: t("Source"),
                accessor: ({ source }) => transactionSourceTypeOptionsMap[source].label,
                filter: getFilterBasedOnKpiMode(getAutocompleteEnumFilter("TransactionSourceType", "source")),
            },
            {
                key: "authorization",
                header: t("Authorization"),
                accessor: request => _jsx(PriorAuthorizationDrawer, { request: request }),
            },
            {
                key: "transactionDateInputGte",
                header: t("Transaction Date From"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "transactionDateInput.gte",
                },
            },
            {
                key: "transactionDateInputLte",
                header: t("Transaction Date To"),
                showOnlyForFilter: true,
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "transactionDateInput.lte",
                    getValueForBackend: value => produce(value, draft => {
                        draft["gte"] = (draft === null || draft === void 0 ? void 0 : draft.gte) ? moment(draft === null || draft === void 0 ? void 0 : draft.gte).format(BACKEND_DATE_TIME_FORMAT) : null;
                        draft["lte"] = (draft === null || draft === void 0 ? void 0 : draft.lte) ? moment(draft === null || draft === void 0 ? void 0 : draft.lte).format(BACKEND_DATE_TIME_FORMAT) : null;
                    }),
                },
            },
        ];
    }, [t, selectedKpiMode]);
};
