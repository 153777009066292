import { useState, useCallback, useMemo } from "react";
import { useOptimaClaimActivitiesColumns } from "./useOptimaClaimActivitiesColumns";
import { useOptimaClaimActivitiesQuery } from "../../../OptimaClaims/gql";
export const useOptimaClaimActivitiesList = (id) => {
    var _a, _b, _c;
    const columns = useOptimaClaimActivitiesColumns();
    const pageSize = 3;
    const defaultInput = {
        first: pageSize,
        after: null,
        before: null,
        last: null,
        filter: { claimId: id || "" },
    };
    const [input, setInput] = useState(defaultInput);
    const { data: activitiesData, loading: isLoading } = useOptimaClaimActivitiesQuery({
        variables: input,
        fetchPolicy: "no-cache",
    });
    const pageInfo = (_a = activitiesData === null || activitiesData === void 0 ? void 0 : activitiesData.optimaClaimActivities) === null || _a === void 0 ? void 0 : _a.pageInfo;
    const edges = ((_b = activitiesData === null || activitiesData === void 0 ? void 0 : activitiesData.optimaClaimActivities) === null || _b === void 0 ? void 0 : _b.edges) || [];
    const totalCount = ((_c = activitiesData === null || activitiesData === void 0 ? void 0 : activitiesData.optimaClaimActivities) === null || _c === void 0 ? void 0 : _c.totalCount) || 0;
    const tableData = useMemo(() => edges.map(e => e === null || e === void 0 ? void 0 : e.node), [edges]);
    const handleGotoNext = useCallback(() => {
        setInput(prev => (Object.assign(Object.assign({}, prev), { first: pageSize, after: (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor) || null, last: null, before: null })));
    }, [pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor, pageSize]);
    const handleGoToPrevious = useCallback(() => {
        setInput(prev => (Object.assign(Object.assign({}, prev), { last: pageSize, before: (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.startCursor) || null, first: null, after: null })));
    }, [pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.startCursor, pageSize]);
    return {
        tableData,
        columns,
        pageSize,
        totalCount,
        isLoading,
        hasNextPage: (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) || false,
        hasPreviousPage: (pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasPreviousPage) || false,
        handleGotoNext,
        handleGoToPrevious,
    };
};
