import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { TimeMachineIcon } from "@toolkit/ui";
import { isVendorAdmin } from "@/utils";
import { UserInfoType } from "@/shared/configs/oidc";
import { DoctorListContainer, DoctorUpdateContainer } from "../containers";
import { doctorsPaths, doctorsRoute } from "./DoctorsPaths";

export const doctorsRoutes: (props: { navigate: NavigateFunction; userInfo: UserInfoType }) => RouteItem = ({ navigate, userInfo }) => {
  return {
    id: "doctors-routes",
    text: i18n.t("Doctors", { ns: "provider" }),
    route: doctorsRoute,
    icon: <TimeMachineIcon />,
    isProhibited: !(isVendorAdmin(userInfo) && hasPermission(PermissionEnum.ManageDoctors)),
    subItems: [
      {
        id: "doctor-list-route",
        route: doctorsPaths.list.route,
        fullPath: doctorsPaths.list.fullPath,
        element: <DoctorListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "doctor-update-route",
        route: doctorsPaths.update.route,
        fullPath: doctorsPaths.update.fullPath,
        hidden: true,
        element: <DoctorUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
