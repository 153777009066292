import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getApolloContextFormToken } from "shared/utils";
import { useGetPatientProfileDataByIdLazyQuery, useGetPatientProfileDataByVisitIdLazyQuery } from "../../gql";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";

type PatientDataByVisitProps = {
  visitId?: string;
  patientId?: string;
};

export const usePatientDataByVisit = (props: PatientDataByVisitProps) => {
  const [params] = useSearchParams();
  const { accessToken } = useOidcUserProfile();

  const token = params.get("token")! || accessToken;
  const visitId = params.get("visitId") || props.visitId;
  const patientId = params.get("patientId") || props.patientId;

  const [getPatientById, { data: dataByPatientId, loading: loadingByPatientId }] = useGetPatientProfileDataByIdLazyQuery({
    context: getApolloContextFormToken(token!),
  });
  const [getPatientByVisitId, { data: dataByVisitId, loading: loadingByVisitId }] = useGetPatientProfileDataByVisitIdLazyQuery({
    context: getApolloContextFormToken(token!),
  });
  const patient = dataByPatientId?.patient || dataByVisitId?.visit?.patient;

  useEffect(() => {
    if (token) {
      if (visitId) {
        getPatientByVisitId({
          variables: {
            visitId,
          },
        });
      } else if (patientId) {
        getPatientById({
          variables: {
            patientId,
          },
        });
      }
    }
  }, [patientId, getPatientById, visitId, token, getPatientByVisitId]);
  const loadingVisit = loadingByPatientId || loadingByVisitId;

  return {
    patient,
    loadingVisit,
  };
};
