import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, TextField, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useGetOrderAddressQuery } from "../gql";

export const OrderAddressModal: FC<{ orderId: string; title: string }> = ({ orderId, title }) => {
  const { t } = useTranslation("provider");
  const currentDir = i18n.dir(i18n.language);
  const { data } = useGetOrderAddressQuery({
    variables: {
      orderId: orderId,
    },
  });
  const address = data?.marketplaceOrder?.address;
  return (
    <CustomDialog type='info' title={title}>
      <>
        {!address ? (
          <Typography> {t("There is No Address Information")}</Typography>
        ) : (
          <Grid container spacing={2}>
            {address?.city && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  dir={currentDir}
                  fullWidth
                  placeholder={t("City")}
                  value={pickLocalizedValue(address?.city, address?.cityAr)}
                  label={t("City")}
                />
              </Grid>
            )}
            {address?.area && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  dir={currentDir}
                  fullWidth
                  placeholder={t("Area")}
                  value={pickLocalizedValue(address?.area, address?.areaAr)}
                  label={t("Area")}
                />
              </Grid>
            )}
            {address?.streetAddress1 && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder={t("Street Address 1")}
                  value={pickLocalizedValue(address?.streetAddress1, address?.streetAddress1Ar)}
                  label={t("Street Address 1")}
                />
              </Grid>
            )}
            {address?.streetAddress2 && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder={t("Street Address 2")}
                  value={pickLocalizedValue(address?.streetAddress2, address?.streetAddress2Ar)}
                  label={t("Street Address 2")}
                />
              </Grid>
            )}
            {address?.buildingNumber && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder={t("Building Number")}
                  value={t(address?.buildingNumber)}
                  label={t("Building Number")}
                />
              </Grid>
            )}
          </Grid>
        )}
      </>
    </CustomDialog>
  );
};
