import { i18n } from "@toolkit/i18n";
import {
  createBranchAutocompleteOption,
  createCityAutocompleteOption,
  createDepartmentAutocompleteOption,
  createDoctorAutocompleteOption,
} from "@health/autocompletes";
import { DoctorNurseAssignment, UserVendorUserType } from "@health/queries/types";
import { isEqual } from "lodash";
import { removeProviderLocalStorageItem, setProviderLocalStorageItem } from "@/utils";
import { userWorkspaceNurseVar, userWorkspaceDoctorVar } from "../vars";
import { IUserWorkspaceDoctorVar, IUserWorkspaceNurseVar } from "../types";
import { IUserWorkspaceDoctorUpsertFormValues } from "../forms/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertFormSchema";
import { IUserWorkspaceNurseUpsertFormValues } from "../forms/UserWorkspaceNurseUpsert/UserWorkspaceNurseUpsertFormSchema";

export const convertUserWorkspaceDoctorToFormValues = (
  userWorkspaceDoctor: IUserWorkspaceDoctorVar
): Partial<IUserWorkspaceDoctorUpsertFormValues> => {
  return {
    branch: createBranchAutocompleteOption(userWorkspaceDoctor?.branch),
    city: userWorkspaceDoctor?.city ? createCityAutocompleteOption(userWorkspaceDoctor?.city) : undefined,
  };
};

export const convertUserWorkspaceNurseToFormValues = (
  nurseCurrentWorkspace: IUserWorkspaceNurseVar
): IUserWorkspaceNurseUpsertFormValues => {
  return {
    branch: createBranchAutocompleteOption(nurseCurrentWorkspace?.branch),
    department: createDepartmentAutocompleteOption(nurseCurrentWorkspace?.department),
    doctors: nurseCurrentWorkspace?.doctors?.map(item => createDoctorAutocompleteOption(item)),
  };
};

export const getNurseCurrentWorkspaceMessage = (length: number) => {
  if (length === 1) {
    return i18n.t("Assigned to 1 doctor", { ns: "provider" });
  }

  if (length > 1) {
    return i18n.t("assigned-to-n-doctors", { doctorsCount: length, ns: "provider" });
  }

  return i18n.t("Select Doctors");
};

export const removeUserWorkspaceData = (vendorUserType: string) => {
  if (vendorUserType?.toLowerCase() === UserVendorUserType.Nurse.toLowerCase()) {
    userWorkspaceNurseVar(undefined);
    removeProviderLocalStorageItem("UserWorkspaceNurse");
  } else {
    userWorkspaceDoctorVar(undefined);
    removeProviderLocalStorageItem("UserWorkspaceDoctor");
  }
};

export const updateUserWorkspaceNurseData = (
  doctorNurseAssignments: DoctorNurseAssignment[],
  nurseCurrentWorkspace: IUserWorkspaceNurseVar | null
) => {
  const department = doctorNurseAssignments?.[0]?.department;

  const doctors =
    doctorNurseAssignments?.map(item => ({
      id: item?.doctor?.id!,
      user: item?.doctor?.user,
    })) || [];

  const userWorkspaceNurseData: IUserWorkspaceNurseVar = {
    branch: department?.branch!,
    department: {
      id: department?.id!,
      name: department?.name!,
    },
    doctors,
  };

  if (isEqual(userWorkspaceNurseData, nurseCurrentWorkspace)) return;

  userWorkspaceNurseVar(userWorkspaceNurseData);
  setProviderLocalStorageItem("UserWorkspaceNurse", userWorkspaceNurseData);
};
