/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NurseDoctorsAssignMutationVariables = Types.Exact<{
  input: Array<Types.DoctorNurseAssignmentInput> | Types.DoctorNurseAssignmentInput;
}>;


export type NurseDoctorsAssignMutation = { __typename?: 'Mutation', saveDoctorNurseAssignment?: Array<{ __typename?: 'DoctorNurseAssignment', id: string } | null> | null };


export const NurseDoctorsAssignDocument = gql`
    mutation NurseDoctorsAssign($input: [DoctorNurseAssignmentInput!]!) {
  saveDoctorNurseAssignment(input: $input) {
    id
  }
}
    `;
export type NurseDoctorsAssignMutationFn = Apollo.MutationFunction<NurseDoctorsAssignMutation, NurseDoctorsAssignMutationVariables>;

/**
 * __useNurseDoctorsAssignMutation__
 *
 * To run a mutation, you first call `useNurseDoctorsAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNurseDoctorsAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nurseDoctorsAssignMutation, { data, loading, error }] = useNurseDoctorsAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNurseDoctorsAssignMutation(baseOptions?: Apollo.MutationHookOptions<NurseDoctorsAssignMutation, NurseDoctorsAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NurseDoctorsAssignMutation, NurseDoctorsAssignMutationVariables>(NurseDoctorsAssignDocument, options);
      }
export type NurseDoctorsAssignMutationHookResult = ReturnType<typeof useNurseDoctorsAssignMutation>;
export type NurseDoctorsAssignMutationResult = Apollo.MutationResult<NurseDoctorsAssignMutation>;
export type NurseDoctorsAssignMutationOptions = Apollo.BaseMutationOptions<NurseDoctorsAssignMutation, NurseDoctorsAssignMutationVariables>;