import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { doctorsPaths } from "../constants";

export const useSetDoctorsBreadcrumbs = (containerType: "LIST" | "UPDATE", name?: string) => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Doctors");

  const doctorListBreadcrumb = useMemo(() => {
    return {
      name: t("Doctors"),
      route: doctorsPaths.list.fullPath,
    };
  }, [t]);

  const setDoctorListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setDoctorUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [doctorListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, doctorListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setDoctorListContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setDoctorUpdateContainerBreadcrumb();
    }
  }, [containerType, setDoctorListContainerBreadcrumb, setDoctorUpdateContainerBreadcrumb]);
};
