import { Box, Button } from "@toolkit/ui";
import classNames from "classnames";
import { FC } from "react";
import { HeaderDetailsTabMap } from "../constants";
import { HeaderDetailsTab } from "../types";
import { useProductOrdersHeaderStyles } from "./useProductOrdersHeaderStyles";

interface ProductOrdersHeaderProps {
  selectedTab: HeaderDetailsTab;
  onChangeTab: (tab: HeaderDetailsTab) => void;
}

export const ProductOrdersHeader: FC<ProductOrdersHeaderProps> = ({ selectedTab, onChangeTab }) => {
  const { classes } = useProductOrdersHeaderStyles();

  return (
    <Box className={classes.root}>
      {HeaderDetailsTabMap?.map(tab => (
        <Button
          key={tab.getLabel()}
          variant='text'
          className={classNames(classes.button, { [classes.selected]: selectedTab === tab })}
          onClick={() => onChangeTab(tab)}
        >
          {tab.getLabel()}
        </Button>
      ))}
    </Box>
  );
};
