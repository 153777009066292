import { FormActions, FormAutocomplete, FormCard, FormTextField, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { FC, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { Doctor } from "@health/queries/types";
import { doctorSeniorityOptions } from "@health/enum-options";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { doctorsPaths } from "../../constants";
import { convertDoctorToFormValues } from "../../others";
import { doctorUpdateFormSchema, doctorUpdateFormDefaultValues, IDoctorUpdateFormValues } from "./DoctorUpdateFormSchema";

export type IDoctorUpdateFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IDoctorUpdateFormValues;
  };
};

type DoctorUpdateFormProps = {
  doctor: Doctor;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IDoctorUpdateFormEvent) => void;
};

export const DoctorUpdateForm: FC<DoctorUpdateFormProps> = props => {
  const { doctor, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("provider");
  const navigate = useNavigate();

  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IDoctorUpdateFormValues>({
    defaultValues: doctorUpdateFormDefaultValues,
    schema: doctorUpdateFormSchema,
  });

  const { control, setValues, handleSubmit } = form;

  const onSubmit = (values: IDoctorUpdateFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onCancelClick = () => {
    navigate(doctorsPaths.list.fullPath);
  };

  useEffect(() => {
    if (doctor) {
      const _doctor = convertDoctorToFormValues(doctor);
      setValues(_doctor);
    }
  }, [doctor, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={t("Update")}
              onNavigation={onCancelClick}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <FormCard title={t("Doctor Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormAutocomplete name={"seniority"} label={t("Seniority")} options={doctorSeniorityOptions} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormTextField name={"bio"} label={t("Bio")} placeholder={t("Bio")} multiline rows={6} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ImageUploadController name={"photo"} control={control} validationRation={true} token={accessToken!} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
