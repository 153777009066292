/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHealthPackageOrdersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceOrderSortingInput>;
  filter?: Types.InputMaybe<Types.MarketplaceOrderFilterInput>;
}>;


export type GetHealthPackageOrdersQuery = { __typename?: 'Query', healthPackageOrders?: { __typename?: 'MarketplaceOrderConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, startCursor?: string | null, hasPreviousPage: boolean } | null, edges: Array<{ __typename?: 'MarketplaceOrderEdge', node: { __typename?: 'MarketplaceOrder', id: string, status: Types.MarketplaceOrderStatus, totalNetAmount: any, createdDate: any, user: { __typename?: 'User', fullName?: string | null, mobile?: string | null, email?: string | null }, orderEvents: Array<{ __typename?: 'MarketplaceOrderEvent', date: any, id: string, status: Types.MarketplaceOrderStatus, user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, defaultBranch?: { __typename?: 'Branch', name: string } | null } }> } }> } | null };


export const GetHealthPackageOrdersDocument = gql`
    query getHealthPackageOrders($first: Int, $after: String, $last: Int, $before: String, $sortBy: MarketplaceOrderSortingInput, $filter: MarketplaceOrderFilterInput) {
  healthPackageOrders: marketplaceOrders(
    first: $first
    after: $after
    last: $last
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    edges {
      node {
        id
        status
        user {
          fullName
          mobile
          email
        }
        orderEvents {
          date
          id
          status
          user {
            id
            firstName
            lastName
            defaultBranch {
              name
            }
          }
        }
        totalNetAmount
        createdDate
      }
    }
  }
}
    `;

/**
 * __useGetHealthPackageOrdersQuery__
 *
 * To run a query within a React component, call `useGetHealthPackageOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthPackageOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthPackageOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetHealthPackageOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>(GetHealthPackageOrdersDocument, options);
      }
export function useGetHealthPackageOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>(GetHealthPackageOrdersDocument, options);
        }
export type GetHealthPackageOrdersQueryHookResult = ReturnType<typeof useGetHealthPackageOrdersQuery>;
export type GetHealthPackageOrdersLazyQueryHookResult = ReturnType<typeof useGetHealthPackageOrdersLazyQuery>;
export type GetHealthPackageOrdersQueryResult = Apollo.QueryResult<GetHealthPackageOrdersQuery, GetHealthPackageOrdersQueryVariables>;