/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NurseDoctorListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.DoctorNurseAssignmentFilterInput>;
}>;


export type NurseDoctorListQuery = { __typename?: 'Query', doctorNurseAssignments?: { __typename?: 'DoctorNurseAssignmentCountableConnection', edges: Array<{ __typename?: 'DoctorNurseAssignmentCountableEdge', node: { __typename?: 'DoctorNurseAssignment', id: string, department?: { __typename?: 'Department', id: string, name: string, branch: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } } | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } }> } | null };


export const NurseDoctorListDocument = gql`
    query NurseDoctorList($after: String, $before: String, $first: Int, $last: Int, $filter: DoctorNurseAssignmentFilterInput) {
  doctorNurseAssignments(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
  ) {
    edges {
      node {
        id
        department {
          id
          name
          branch {
            id
            name
            nameAr
          }
        }
        doctor {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useNurseDoctorListQuery__
 *
 * To run a query within a React component, call `useNurseDoctorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNurseDoctorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNurseDoctorListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNurseDoctorListQuery(baseOptions?: Apollo.QueryHookOptions<NurseDoctorListQuery, NurseDoctorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NurseDoctorListQuery, NurseDoctorListQueryVariables>(NurseDoctorListDocument, options);
      }
export function useNurseDoctorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NurseDoctorListQuery, NurseDoctorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NurseDoctorListQuery, NurseDoctorListQueryVariables>(NurseDoctorListDocument, options);
        }
export type NurseDoctorListQueryHookResult = ReturnType<typeof useNurseDoctorListQuery>;
export type NurseDoctorListLazyQueryHookResult = ReturnType<typeof useNurseDoctorListLazyQuery>;
export type NurseDoctorListQueryResult = Apollo.QueryResult<NurseDoctorListQuery, NurseDoctorListQueryVariables>;