import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "@toolkit/core";
import { IUserWorkspaceDoctorVar, IUserWorkspaceNurseVar } from "@/pages/UserWorkspace/types";

type ProviderLocalStorageValues = {
  UserWorkspaceDoctor: IUserWorkspaceDoctorVar;
  UserWorkspaceNurse: IUserWorkspaceNurseVar;
};

type ProviderLocalStorageKeys = keyof ProviderLocalStorageValues;

export const getProviderLocalStorageItem = <K extends ProviderLocalStorageKeys>(key: K): ProviderLocalStorageValues[K] | null => {
  return getLocalStorageItem(key);
};

export const setProviderLocalStorageItem = <K extends ProviderLocalStorageKeys>(key: K, value: ProviderLocalStorageValues[K]) => {
  setLocalStorageItem(key, value);
};

export const removeProviderLocalStorageItem = (key: ProviderLocalStorageKeys) => {
  removeLocalStorageItem(key);
};
