import { Box, CircularProgress, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useProfileContext } from "../../../context/ProfileContext";
import { useRequestAccess } from "./useRequestAccess";
import { ConsentStatusView } from "./ConsentStatusView";
import { ConsentPendingView } from "./ConsentPendingView";
import { AccessDeniedView } from "./AccessDeniedView";

export const RequestAccessView: FC = () => {
  const theme = useTheme();
  const { tokenId, errorCode, consentStatus, patientMedicalProfileQueryLoading } = useProfileContext();
  const { loading, patientProfileConsentFormData, handleReset, handleRequestAccess } = useRequestAccess();
  const consentPending = errorCode === "PATIENT_PROFILE_CONSENT_PENDING";

  return (
    <Box
      sx={{
        zIndex: 4,
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        paddingTop: 50,
        justifyContent: "center",
        backdropFilter: "blur(8px)",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: theme.palette.common.black,
          opacity: 0.4,
        },
      }}
    >
      <Box
        sx={{
          height: "fit-content",
          backgroundColor: "white",
          borderRadius: 3,
          width: "50%",
          boxShadow: theme.mixins.shadows.main,
          zIndex: theme.zIndex.modal,
        }}
      >
        {patientMedicalProfileQueryLoading ? (
          <Box
            p={1}
            sx={{
              height: 150,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <CircularProgress size={60} />
          </Box>
        ) : (
          <Box>
            {consentStatus ? (
              <ConsentStatusView onReset={handleReset} />
            ) : (
              <>
                {patientProfileConsentFormData || tokenId || consentPending ? (
                  <ConsentPendingView />
                ) : (
                  <AccessDeniedView isLoading={loading} onRequestAccess={handleRequestAccess} />
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
