export const optimaRequestKpi = {
    all: {
        type: "all",
        variables: {
            fetchAll: true,
            fetchRejectedAuth: false,
            fetchWithoutClaim: false,
        },
        accessor: "optimaPriorRequests",
    },
    rejected: {
        type: "rejected",
        variables: {
            fetchAll: false,
            fetchRejectedAuth: true,
            fetchWithoutClaim: false,
        },
        accessor: "optimaPriorRequestsRejectedAuth",
    },
    withoutClaim: {
        type: "withoutClaim",
        variables: {
            fetchAll: false,
            fetchRejectedAuth: false,
            fetchWithoutClaim: true,
        },
        accessor: "optimaPriorRequestsWithoutClaim",
    },
};
