import { useEffect } from "react";
import { useGridContext } from "@toolkit/ui";
export const ActiveFiltersValues = props => {
    const { activeFiltersProps } = useGridContext();
    const { onFiltersChange } = props;
    useEffect(() => {
        onFiltersChange === null || onFiltersChange === void 0 ? void 0 : onFiltersChange(activeFiltersProps === null || activeFiltersProps === void 0 ? void 0 : activeFiltersProps.formattedFiltersValues);
    }, [JSON.stringify(activeFiltersProps === null || activeFiltersProps === void 0 ? void 0 : activeFiltersProps.filters)]);
    return null;
};
