import { GuidedCareJourneyItemStatus } from "@health/queries/types";
import { patientProfileColors } from "../../constants/colors";
import { pickLocalizedValue } from "@toolkit/i18n";
import { uniq } from "lodash";

const monthOrder = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const statusMapper = (status?: string) => {
  switch (status) {
    case GuidedCareJourneyItemStatus.Pending:
    case GuidedCareJourneyItemStatus.Rescheduled:
      return { color: patientProfileColors.orange, title: "Tentative appointment", iconStatus: "qmark", viewBoxStatus: "-3 -5 25 25" };
    case GuidedCareJourneyItemStatus.Completed:
      return { color: patientProfileColors.green, iconStatus: "checkok", viewBoxStatus: "-5 -4 25 25" };
    case GuidedCareJourneyItemStatus.Cancelled:
    case GuidedCareJourneyItemStatus.Missed:
      return { color: patientProfileColors.red, iconStatus: "close2", viewBoxStatus: "-5 -5 25 25" };
    default:
      return { color: patientProfileColors.orange, title: "Tentative appointment", iconStatus: "qmark", viewBoxStatus: "-3 -5 25 25" };
  }
};

export const updateTimelineForInterventions = (timeline: any) => {
  const updatedTimeline = timeline?.map(month => {
    const interventions =
      month?.activities
        ?.filter(activity => activity?.templateIntervention?.name)
        ?.map(activity => pickLocalizedValue(activity.templateIntervention?.name!, activity.templateIntervention?.nameAr!)) || [];

    return {
      ...month,
      interventions: uniq(interventions),
      hasIntervention: month?.activities.some(activity => activity?.templateIntervention?.id),
    };
  });

  const interventionMonths = updatedTimeline?.filter(month => month?.hasIntervention).map(month => month?.label);

  const minMonth = interventionMonths?.[0];
  const maxMonth = interventionMonths?.[interventionMonths?.length - 1];

  return updatedTimeline.map(month => {
    const monthIndex = monthOrder.indexOf(month.label);
    const minMonthIndex = monthOrder.indexOf(minMonth || "");
    const maxMonthIndex = monthOrder.indexOf(maxMonth || "");

    const isBetweenMinMax = minMonthIndex >= 0 && maxMonthIndex >= 0 && monthIndex >= minMonthIndex && monthIndex <= maxMonthIndex;

    return {
      ...month,
      hasIntervention: isBetweenMinMax || month.hasIntervention,
      isMinMonth: month.label === minMonth,
      isMaxMonth: month.label === maxMonth,
    };
  });
};
