import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomIcon, FormCard, TextField, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useToPatientNote } from "./useToPatientNote";

export const ToPatientNote: FC<{ visitId?: string; token?: string }> = ({ visitId = "", token }) => {
  const { t } = useTranslation("provider");
  const { errors, methods, isSubmitting, defaultData, isDataLoading, handleSubmit } = useToPatientNote({
    visitId,
    token,
  });
  const theme = useTheme();
  return (
    <Box sx={{ background: theme.palette.common.white, flex: 2.5, width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <FormCard title='' loading={isDataLoading} doYouHaveData={!isDataLoading} height='0px'>
            <Box sx={{ marginBottom: "8px" }}>
              <TextField
                multiline
                rows={3}
                fullWidth
                label={t("Patient's Notes")}
                placeholder={t("Patient's Notes")}
                defaultValue={defaultData}
                error={Boolean(errors?.doctorNote?.message)}
                helperText={t(errors?.doctorNote?.message!)}
                {...methods.register("doctorNote", { required: getRequiredValidation(t, true) })}
              />
            </Box>
            <Box component={"footer"} sx={{ display: "flex" }}>
              <Button
                type='submit'
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={10} /> : <CustomIcon icon='add' color={theme.palette.common.white} />}
              >
                {isSubmitting ? t("submitting") + "..." : t("Save")}
              </Button>
            </Box>
          </FormCard>
        </form>
      </FormProvider>
    </Box>
  );
};
