import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ValueWithLabel } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useRemittanceDrawerStyles } from "../RemittanceDrawer/RemittanceDrawerStyles";
import moment from "moment";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
export const ClaimEncounterDetails = ({ claim }) => {
    var _a, _b, _c, _d;
    const { classes } = useRemittanceDrawerStyles();
    const { t } = useTranslation("domains");
    return (_jsxs(Box, { className: classes.sectionContainer, children: [_jsx(ValueWithLabel, { labelProps: { className: classes.label }, valueProps: { className: classes.value }, label: t("Start Type"), value: ((_a = claim.encounterStartTypeLookup) === null || _a === void 0 ? void 0 : _a.name) || ((_b = claim.encounterStartType) === null || _b === void 0 ? void 0 : _b.toString()) || "-" }), _jsx(ValueWithLabel, { labelProps: { className: classes.label }, valueProps: { className: classes.value }, label: t("Start Date"), value: claim.encounterStart ? moment(claim.encounterStart).format(DATE_FORMAT_FOR_TABLE_LONG) : "-" }), _jsx(ValueWithLabel, { labelProps: { className: classes.label }, valueProps: { className: classes.value }, label: t("End Type"), value: ((_c = claim.encounterEndTypeLookup) === null || _c === void 0 ? void 0 : _c.name) || ((_d = claim.encounterEndType) === null || _d === void 0 ? void 0 : _d.toString()) || "-" }), _jsx(ValueWithLabel, { labelProps: { className: classes.label }, valueProps: { className: classes.value }, label: t("End Date"), value: claim.encounterEnd ? moment(claim.encounterEnd).format(DATE_FORMAT_FOR_TABLE_LONG) : "-" })] }));
};
