import { OptimaClaimsActivitiesGridContainer } from "@health/domains";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBreadCrumbs } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { IProviderOptimaClaimsActivitiesListContainerParams } from "../../type";

export const ProviderOptimaClaimsActivitiesList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("provider");
  const { claimId } = useParams<IProviderOptimaClaimsActivitiesListContainerParams>();

  useEffect(() => {
    setBreadCrumb({
      title: t("Optima Claims Activities"),
    });
  }, []);

  return (
    <OptimaClaimsActivitiesGridContainer
      filters={{
        claimId,
      }}
    />
  );
};
