import { Maybe } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  DoctorAppointmentRejectMutation,
  useDoctorAppointmentRejectMutation,
  useGetDoctorAppointmentRejectionReasonsQuery,
} from "pages/appointments/gql";
import { isDateExpired } from "pages/appointments/utils";
import { useState } from "react";
import { NotificationItemAction } from "shared/components";

type RejectAppointmentType = {
  id: string;
  requestedTimeSlots?: Maybe<Maybe<string>[]>;
} & NotificationItemAction;

export const useAppointmentReject = ({ id, requestedTimeSlots, handleNotificationActionComplete }: RejectAppointmentType) => {
  const [state, setState] = useState({ reason: "", other: "", isOther: false });

  const { t } = useTranslation("provider");
  const { open: isOpen, handleToggle } = useOpenState();
  const { failed, succeeded } = useAddToast();

  const { data, loading: reasonsLoading } = useGetDoctorAppointmentRejectionReasonsQuery({
    skip: !isOpen,
  });

  const reasons = data?.rejectionReasons?.length ? data?.rejectionReasons : [{ id: "1", text: "Other", text_ar: t("Other") }];

  const parsedRequestedTimeSlots = requestedTimeSlots?.[0] && JSON.parse(requestedTimeSlots?.[0]!)?.startTime;

  const isExpiredDate = isDateExpired(parsedRequestedTimeSlots);

  const [doctorAppointmentRejectMutation, { loading: isRejectSubmitting }] = useDoctorAppointmentRejectMutation({
    onCompleted: (mutationData: DoctorAppointmentRejectMutation) => {
      const errors = mutationData?.doctorAppointmentReject?.appointmentErrors;
      if (errors?.length) {
        const formatted = formatMessageErrors(errors);
        failed(formatted);
      } else {
        succeeded(t("Appointment Rejected"));
        handleNotificationActionComplete?.();
        handleToggle();
      }
    },
    onError: ({ graphQLErrors }) => {
      const formatted = formatGraphQLError(graphQLErrors);
      failed(formatted || t("Appointment Reject failed"));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.doctorAppointmentReject?.appointment?.id, __typename: "Appointment" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const disabled = reasonsLoading || isRejectSubmitting || state.isOther ? !state.other : !state.reason;

  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "Other" || e.target.value.includes("reason for rejection")) {
      setState({ ...state, isOther: true, reason: e.target.value });
    } else {
      setState({ isOther: false, reason: e.target.value, other: "" });
    }
  };

  const handleChangeOtherReason = e => {
    setState({ ...state, other: e.target.value, isOther: true });
  };

  const handleSubmit = () => {
    doctorAppointmentRejectMutation({
      variables: {
        id,
        input: {
          rejectionReason: state.isOther ? state.other : state.reason,
        },
      },
    });
  };

  return {
    state,
    isOpen,
    reasons,
    isExpiredDate,
    disabled,
    handleToggle,
    handleSubmit,
    handleChangeOtherReason,
    handleChangeReason,
  };
};
