import { Box, Card, ExpandIcon, Grid, IconButton, SkeletonCard, Typography } from "@toolkit/ui";
import moment from "moment";
import { sourceOptionsMap } from "@health/enum-options";
import React, { FC, PropsWithChildren } from "react";
import { usePatientHealthParameterCardStyle } from "./usePatientHealthParameterCardStyle";
import { PatientHealthParameter } from "@health/queries/types/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { PatientHealthParameterEmpty } from "../PatientHealthParameterEmpty/PatientHealthParameterEmpty";
import { useGetHealthParametersQuery } from "@/pages/PatientHealthParameters/gql";
import { getApolloContextFormToken } from "@/shared/utils";

export type IPatientHealthParameterCardEvent = {
  type: "EXPAND_CLICK";
};

type PatientHealthParameterCardProps = PropsWithChildren<{
  code: string;
  token: string;
  patientHealthParameter: PatientHealthParameter;
  isLoading?: boolean;
  isExpandIconShown?: boolean;
  onChange: (event: IPatientHealthParameterCardEvent) => void;
}>;

export const PatientHealthParameterCard: FC<PatientHealthParameterCardProps> = props => {
  const { code, token, patientHealthParameter, isLoading, isExpandIconShown = true, onChange } = props;

  const { t } = useTranslation("provider");

  const { classes } = usePatientHealthParameterCardStyle();

  const { data, loading } = useGetHealthParametersQuery({
    context: getApolloContextFormToken(token!),
    variables: {
      first: 1,
      filter: {
        code: [code],
      },
    },
    skip: !code || patientHealthParameter,
  });

  const name = data?.getHealthParameters?.edges?.[0]?.node?.display;

  const onExpandIconClick = () => {
    onChange({
      type: "EXPAND_CLICK",
    });
  };

  return (
    <>
      {isLoading || loading ? (
        <SkeletonCard height={200} />
      ) : (
        <Card className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.infoWrapper}>
                <Box>
                  <Typography className={classes.title}>
                    {pickLocalizedValue(
                      patientHealthParameter?.healthParameter?.display,
                      patientHealthParameter?.healthParameter?.arabicDisplay
                    ) || name}
                  </Typography>

                  {patientHealthParameter?.createdDate && (
                    <Typography className={classes.date}>
                      {moment(patientHealthParameter?.createdDate).format("DD MMM YYYY HH:mm")}
                    </Typography>
                  )}

                  {patientHealthParameter?.source && (
                    <Typography className={classes.source}>
                      {t("By")} {sourceOptionsMap[patientHealthParameter?.source]?.label}
                    </Typography>
                  )}
                </Box>

                {isExpandIconShown && (
                  <IconButton onClick={onExpandIconClick}>
                    <ExpandIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              {props.children || <PatientHealthParameterEmpty />}
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};
