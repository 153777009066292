import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button } from "@toolkit/ui";
import { FC } from "react";
import { QRCodeModal } from "@/shared/components";

type DepartmentQRCodeProps = {
  id: string;
  name: string;
};

export const DepartmentQRCode: FC<DepartmentQRCodeProps> = props => {
  const { id, name } = props;

  const { t } = useTranslation("provider");

  const { open, handleOpen, handleClose } = useOpenState();

  return (
    <>
      <Button onClick={handleOpen}>{t("View QR")}</Button>

      <QRCodeModal
        title={t("Department QR Code")}
        open={open}
        printTitle={t("Check-in for your appointment by scanning the QR code")}
        qrCode={{ app: "ioHealth", version: 1, type: "department", typeId: id, typeLabel: name }}
        withPrint
        handleClose={handleClose}
      />
    </>
  );
};
