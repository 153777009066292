import { FC } from "react";
import { useParams } from "react-router-dom";
import { MeetoraGuestCall } from "@health/meetora";

export const VisitCallGuestPage: FC = () => {
  const params = useParams();

  return <MeetoraGuestCall token={params["token"]!} />;
};

export default VisitCallGuestPage;
