import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";
import { useOrderVendorFinishProcessingMutation } from "../gql";

export const DispenseButton: FC<{ id: string; submitLabel: string; onDone?: () => void }> = ({ id, submitLabel, onDone }) => {
  const { t } = useTranslation("provider");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [orderFinishProcessing, { loading }] = useOrderVendorFinishProcessingMutation({
    onCompleted: () => {
      succeeded(t("Order is Finished Processing delivery"));
      onDone?.();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleOrderVendorFinishProcessing = (): void => {
    orderFinishProcessing({
      variables: { orderId: id },
    });
  };

  const className = submitLabel === t("Dispense eRx") ? `${classes.accept} ${classes.dispense} ` : classes.accept;

  return (
    <CircularIntegration loading={loading}>
      <Button disabled={loading} className={className} onClick={handleOrderVendorFinishProcessing}>
        {submitLabel}
      </Button>
    </CircularIntegration>
  );
};
