import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { userWorkspaceNurseVar } from "@/pages/UserWorkspace/vars";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { isNurseUser } from "utils";

export const useVisitsHistoryListPage = () => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();
  const { userInfo } = useOidcUserProfile();
  const [isMeOnly, setIsMeOnly] = useState(true);

  const isVendorAdmin = userInfo?.app_role?.toLowerCase() === "admin";
  const selfOnly = isVendorAdmin ? isMeOnly : true;
  const currentNurseWorkDetails = useReactiveVar(userWorkspaceNurseVar);
  const skipCall = isNurseUser(userInfo) && !currentNurseWorkDetails?.doctors?.length;
  const doctors = currentNurseWorkDetails?.doctors?.map(e => e?.id);
  const filters = { doctors: isNurseUser(userInfo) ? doctors : undefined };
  const handleToggleMyOnly = (_, value) => setIsMeOnly(value);

  useEffect(() => {
    setBreadCrumb({ title: t("Visit History"), values: [] });
  }, []);

  return {
    t,
    isMeOnly,
    isVendorAdmin,
    selfOnly,
    handleToggleMyOnly,
    skipCall,
    filters,
  };
};
