import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { FC, useRef } from "react";
import {
  UserWorkspaceDoctorUpsertForm,
  UserWorkspaceDoctorUpsertFormRef,
  IUserWorkspaceDoctorUpsertFormEvent,
} from "../../forms/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertForm";

type UserWorkspaceDoctorUpsertModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  hasClose: boolean;
  onClose: () => void;
  onChange: (values: IUserWorkspaceDoctorUpsertFormEvent) => void;
};

export const UserWorkspaceDoctorUpsertModal: FC<UserWorkspaceDoctorUpsertModalProps> = props => {
  const { isOpen, isLoading, hasClose, onClose, onChange } = props;

  const { t } = useTranslation("provider");

  const userWorkspaceDoctorUpsertFormRef = useRef<UserWorkspaceDoctorUpsertFormRef>(null);

  const onAssignClick = () => {
    userWorkspaceDoctorUpsertFormRef.current?.submit();
  };

  const handleClose = () => {
    if (!hasClose) return;

    onClose();
  };

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      DialogTitleProps={{
        title: t("Assign Branch"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onAssignClick}
          >
            {t("Assign")}
          </Button>
        ),
      }}
    >
      <UserWorkspaceDoctorUpsertForm ref={userWorkspaceDoctorUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
