import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { departmentsPaths } from "../constants";

export const useSetDepartmentsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("provider");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Departments");

  const payerListBreadcrumb = useMemo(() => {
    return {
      name: t("Departments"),
      route: departmentsPaths.list.fullPath,
    };
  }, [t]);

  const setDepartmentListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setDepartmentCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [payerListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, payerListBreadcrumb, t]);

  const setDepartmentUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [payerListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, payerListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setDepartmentListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setDepartmentCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setDepartmentUpdateContainerBreadcrumb();
    }
  }, [containerType, setDepartmentListContainerBreadcrumb, setDepartmentCreateContainerBreadcrumb, setDepartmentUpdateContainerBreadcrumb]);
};
