import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate, useParams } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { Doctor } from "@health/queries/types";
import { useDoctorGetQuery, useDoctorUpdateMutation } from "../../gql";
import { convertDoctorFormValuesToBackEndValues } from "../../others";
import { IDoctorUpdateContainerParams } from "../../types";
import { useSetDoctorsBreadcrumbs } from "../../hooks";
import { doctorsPaths } from "../../constants";
import { DoctorUpdateForm, IDoctorUpdateFormEvent } from "../../forms/DoctorUpdate/DoctorUpdateForm";

export const DoctorUpdateContainer = () => {
  const { t } = useTranslation("provider");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { doctorId } = useParams<IDoctorUpdateContainerParams>();

  const { data, loading } = useDoctorGetQuery({
    variables: {
      id: doctorId!,
    },
    skip: !doctorId,
  });

  const doctor = data?.doctor as Doctor;

  const [updateDoctor, { loading: isSubmitting }] = useDoctorUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.userUpdate?.user?.id) {
        succeeded(t("Doctor updated successfully"));
        navigate(doctorsPaths.list.fullPath);
      } else {
        failed(t("Doctor update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDoctorUpdateFormChange = (event: IDoctorUpdateFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertDoctorFormValuesToBackEndValues(event.payload.values);

      updateDoctor({
        variables: {
          id: doctor?.user?.id!,
          input: values,
        },
      });
    }
  };

  useSetDoctorsBreadcrumbs("UPDATE", doctor?.user?.fullName!);

  return <DoctorUpdateForm doctor={doctor} isLoading={loading} isButtonDisabled={isSubmitting} onChange={onDoctorUpdateFormChange} />;
};
