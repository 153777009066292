import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, ValueWithLabel } from "@toolkit/ui";
import { useResubmissionDrawerStyles } from "./ResubmissionDrawerStyles";
import { toTitleCase } from "@toolkit/core";
export const ResubmissionDrawerDetails = ({ claim }) => {
    const { classes } = useResubmissionDrawerStyles();
    const { t } = useTranslation("domains");
    const commonValueProps = {
        labelProps: {
            className: classes.label,
        },
        valueProps: {
            className: classes.value,
        },
    };
    return (_jsxs(Box, { className: classes.sectionContainer, children: [_jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Resubmission Type"), value: claim.resubmissionType || "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Submission Cycle"), value: toTitleCase((claim === null || claim === void 0 ? void 0 : claim.submissionCycle) || "-") })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Resubmission Related Claim Id"), value: claim.resubmissionRelatedClaimId || "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Resubmission Attachment"), value: claim.resubmissionAttachment || "-" }))] }));
};
