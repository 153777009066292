import { useEffect } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, TruncateTypography, Typography } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { useReactiveVar } from "@apollo/client";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { isDoctorUser, setProviderLocalStorageItem } from "@/utils";
import { useBranchListQuery, useDefaultBranchAssignMutation } from "../../gql";
import { userWorkspaceDoctorVar } from "../../vars";
import { UserWorkspaceDoctorUpsertModal } from "../../modals/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertModal";
import { IUserWorkspaceDoctorUpsertFormEvent } from "../../forms/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertForm";
import { useUserWorkspaceDoctorStyle } from "./useUserWorkspaceDoctorStyle";

export const UserWorkspaceDoctor = () => {
  const { t } = useTranslation("provider");
  const { open, handleOpen, handleClose } = useOpenState();
  const { userInfo } = useOidcUserProfile();

  const { classes, theme } = useUserWorkspaceDoctorStyle();

  const doctorCurrentWorkspace = useReactiveVar(userWorkspaceDoctorVar);

  const branchName = pickLocalizedValue(doctorCurrentWorkspace?.branch?.name, doctorCurrentWorkspace?.branch?.nameAr);

  const { data, loading } = useBranchListQuery({
    variables: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
  });

  const branches = data?.branches?.edges?.map(item => item?.node);

  const [assignDefaultBranch, { loading: isSubmitting }] = useDefaultBranchAssignMutation();

  const onDefaultBranchCreateModalChange = (event: IUserWorkspaceDoctorUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const { branch, city } = event.payload.values;

      const userWorkspaceDoctorData = {
        branch: branch?.value,
        city: city?.value,
      };

      assignDefaultBranch({
        variables: {
          branchId: userWorkspaceDoctorData?.branch?.id,
        },
        onCompleted: mutationData => {
          if (mutationData?.assignDefaultBranch?.branch?.id) {
            userWorkspaceDoctorVar(userWorkspaceDoctorData);
            setProviderLocalStorageItem("UserWorkspaceDoctor", userWorkspaceDoctorData);
            handleClose();
          }
        },
      });
    }
  };

  useEffect(() => {
    if (loading || !branches?.length) return;

    if (!doctorCurrentWorkspace) {
      handleOpen();
    }
  }, [doctorCurrentWorkspace, branches?.length, loading, handleOpen]);

  return (
    <>
      <Box className={classes.wrapper}>
        {!!branchName && <Typography className={classes.message}>{t("Your branch is")}: </Typography>}

        <TruncateTypography
          maxWidth={250}
          lineHeight={1}
          color={theme.palette.primary.main}
          text={branchName || t("Select Your Working Branch")}
          onClick={handleOpen}
        />
      </Box>

      <UserWorkspaceDoctorUpsertModal
        isOpen={open}
        isLoading={isSubmitting}
        hasClose={!!(doctorCurrentWorkspace?.branch && isDoctorUser(userInfo))}
        onClose={handleClose}
        onChange={onDefaultBranchCreateModalChange}
      />
    </>
  );
};
