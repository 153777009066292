import { useMemo } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { DoctorSpecialization } from "@health/queries/types";

export const useDoctorSpecializationsColumns = (): CustomTableColumnProps<DoctorSpecialization>[] => {
  const { t } = useTranslation("provider");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "display",
        header: t("Display"),
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display, arabicDisplay),
      },
    ];
  }, [t]);
};
